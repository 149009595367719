import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {Observable, startWith} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-multi-select-material',
  templateUrl: './multi-select-material.component.html',
  styleUrls: ['./multi-select-material.component.scss'],
})
export class MultiSelectMaterialComponent implements OnInit, AfterViewInit {
  data: string[] = ['Laravel', 'Angular', 'NPM', 'Jquery', 'PHP'];
  dataSelected: boolean[] = [false, false, false, false, false];
  filteredData: Observable<string[]>;

  autocompleteInputControl = new UntypedFormControl();
  allCheckboxControl = new UntypedFormControl();

  allCheckboxValue = false;
  checkedInternal = false;

  autocompleteInputValue = '';
  filterValue = '';

  value: string[] = [];

  @ViewChild('aic')
  public aicRef: ElementRef;

  @ViewChild('daic')
  public daicRef: ElementRef;
  public inputDiv: HTMLDivElement;

  constructor() {
  }

  ngOnInit(): void {
    this.filteredData = this.autocompleteInputControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  ngAfterViewInit(): void {
    this.inputDiv = this.daicRef.nativeElement as HTMLDivElement;
    this.setDataPlaceholderValue();
  }

  private _filter(value: string): string[] {
    this.filterValue = value.toLowerCase();
    return this.data.filter(v => v.toLowerCase().includes(this.filterValue));
  }

  onSelectionChange(value: any) {
    console.log('Data : ', value);
  }

  onRemoved(item: string) {
    const data = this.autocompleteInputControl.value as string[];
    this.removeFirst(data, item);
    this.autocompleteInputControl.setValue(data);
    this.checkedInternal = false;
  }

  private removeFirst(array: string[], toRemove: string): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  onFocus() {
    console.log('### before focus - input control value : ', this.autocompleteInputControl.value);
    this.autocompleteInputControl.setValue('');

    const input = this.daicRef.nativeElement as HTMLDivElement;
    input.setAttribute('data-placeholder', '');
  }

  onBlur(event: Event) {
    console.log('### onBlur - inputValue : ', this.autocompleteInputValue);
    if (this.autocompleteInputValue === '') {
      this.setDataPlaceholderValue();
    }
    this.autocompleteInputControl.setValue('');
  }

  onClose() {
    console.log('### closing - inputValue : ', this.autocompleteInputValue);
    if (this.autocompleteInputValue === '') {
      this.setDataPlaceholderValue();
    }

    this.autocompleteInputControl.setValue('');
  }

  optionClicked(event: Event, item: string) {
    event.stopPropagation();
    this.toggleSelection(item);
  }

  toggleSelection(item: string) {
    console.log('start data : ', this.data);
    console.log('start data Selected : ', this.dataSelected);
    console.log('ts item : ', item);
    const index = this.data.findIndex(i => i === item);
    console.log('for index : ', index);
    console.log('for element : ', this.dataSelected[index]);
    this.dataSelected[index] = !this.dataSelected[index];
    console.log('data Selected : ', this.dataSelected);

    const selected = this.data.filter(i => this.dataSelected[this.data.indexOf(i)]).length;
    console.log('selected : ', selected);
    console.log('filter : ', this.data.filter(i => this.dataSelected[this.data.indexOf(i)]).length)

    if (selected === this.data.length && this.allCheckboxControl.value === false) {
      this.allCheckboxControl.setValue(true);
    } else if (selected < this.data.length && this.allCheckboxControl.value === true) {
      this.allCheckboxControl.setValue(false);
    }

    this.setControlValue();

    if (this.filterValue === '') {
      this.setDataPlaceholderValue();
    }

  }

  toggleSelectAll() {
    console.log('### toggleSelectAll');
    for (let i = 0; i < this.dataSelected.length; i++) {
      this.dataSelected[i] = this.allCheckboxControl.value
    }

    this.setControlValue();

    if (this.filterValue === '') {
      this.setDataPlaceholderValue();
    }
  }

  private setDataPlaceholderValue() {
    let message: any;
    const selectedCount = this.data.filter(c => this.dataSelected[this.data.indexOf(c)]).length;
    if (selectedCount === this.data.length) {
      message = 'All ' + this.data.length + ' items selected'
    } else if (selectedCount > 0) {
      message = this.data.filter(c => this.dataSelected[this.data.indexOf(c)]).length + ' items selected'
    } else {
      message = 'No Filter Selected';
    }

    if (this.inputDiv) {
      this.inputDiv.setAttribute('data-placeholder', message);
    }
  }

  private setControlValue() {
    this.value = this.data.filter(i => {
      // console.log('index : ', this.data.indexOf(i));
      return this.dataSelected[this.data.indexOf(i)];
    });
    console.log('Control Value : ', this.value);
  }

}

