import { FormGroup } from '@angular/forms';

export abstract class AbstractQueryListData<TQueryData, TListData> {
  public form = new FormGroup<any>({}); //  | FormArray;

  // Get Interface Object from form.value
  public getQueryListData(): TQueryData {
    // Add nested if required (this.form.control['controlX'].value)
    const queryData: TQueryData = {
      ...JSON.parse(JSON.stringify(this.form.value)),
    };
    // delete nested['controlX'];

    return queryData;
  }

  public getListData(): TListData {
    return {} as TListData;
  }
}
