import { CurrencyPipe, DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCell',
})
export class FormatCellPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe, private datePipe: DatePipe) {}

  public transform(value: any, format: string, formatOptions?: string): any {
    // console.log('formatting : ', value);
    // console.log('format : ', format);
    if (format && format === 'currency') {
      return this.currencyPipe.transform(value, 'GBP', '');
    } else if (format && format === 'datetime') {
      return this.datePipe.transform(value, 'dd.MM.yy HH:mm');
    }

    return value;
  }
}
