import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Notification } from '../notification.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationSubject: BehaviorSubject<Notification> = new BehaviorSubject<Notification>(
    {} as Notification
  );
  public notificationSubject$: Observable<Notification> = this.notificationSubject.asObservable();

  constructor() {}

  public notify(notification: Notification): void {
    // console.log('### Notification : ', notification);
    this.notificationSubject.next(notification);
  }
}
