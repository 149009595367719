import { Component } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { AbstractValueAccessor } from '../base/abstract-value-accessor';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  providers: [],
})
// TODO: Use NgbDateStruct, then modify setValue and writeValue
export class DateTimeComponent extends AbstractValueAccessor<string> {
  public maxLength = '1024';

  // TODO : Necessary if NgBDateTimeStruct is used
  /*
    public setValue(value: NgbDateStruct | string): void {
      console.log('setValue : ', value);
      if (typeof value === 'string') {
        if (value !== this.internalValue) {
          super.setValue(value);
          // Emit is necessary if this component is used inside another (e.g. range) component
          this.valueChange.emit(value);
        }
      } else {
        // NgbDateStruct
      }
    }
  */

  // TODO : Necessary if NgBDateTimeStruct is used
  /*
    public writeValue(value: string): void {
      console.log('writeValue last Value : ', this.internalValue);
      console.log('writeValue new Value : ', value);
      this.internalValue = value;
      this.valueChange.emit(value);
    }
  */

  public override validate(control: AbstractControl): ValidationErrors | null {
    console.log('Validate control : ', control);

    if (control.value) {
      const value = control.value;
      console.log('Value : ', value);
      // moment check

      let hasError = false;
      const errorValue: any = {};

      if (value > '2030-07-05T11:11') {
        hasError = true;
        errorValue.range = true;
      }

      return hasError ? errorValue : null;
    }
    return null;
  }
}
