import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  constructor(private router: Router) {}

  // Service is not necessary

  public navigate(path: any, parms: any = {}): void {
    console.log('Navigate to : ', path, ' with params : ', parms);
    this.router.navigate([path]);
    /*
    this.router.navigate([path, parms]).then( (e) => {
      if (e) {
        console.log('Navigation is successful!');
      } else {
        console.log('Navigation has failed!');
      }
    });
*/
  }
}
