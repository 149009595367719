import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { LoadingSpinnerService } from '../../services/progress/loading-spinner.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  public showLoadingSpinner = false;

  private delay = 1000;

  public onDestroy$ = new Subject();

  constructor(private loadingSpinnerService: LoadingSpinnerService) {
    console.log('Progress Component init');
  }

  public ngOnInit(): void {
    this.loadingSpinnerService.loading$
      .pipe(takeUntil(this.onDestroy$))
      // Wait a second before showing the spinner
      .pipe(debounceTime(this.delay))
      .subscribe((show) => {
        console.log('Show : ', show);
        this.showLoadingSpinner = show;
        // Debug
        /*
          if (show) {
            this.showLoadingSpinner = show;
          } else {
            setTimeout(() => {
              console.log('Show : ', show);
              this.showLoadingSpinner = show; }, 3000);
          }
        */
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next(null);
  }
}
