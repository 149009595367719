import { Injector } from '@angular/core';

// https://medium.com/better-programming/angular-inheritance-without-effort-8200c8d87972 - not for inject of ActivatedRooute or ElementRef
export class AppInjector {
  private static internalInjector: Injector;

  static set injector(injector: Injector) {
    this.internalInjector = injector;
  }

  static get injector(): Injector {
    return this.internalInjector;
  }
}
