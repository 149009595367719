import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  private requestCounter = 0;

  public onRequestStarted(method: string): void {
    // console.log('Request started');
    this.requestCounter++;
    if (this.requestCounter === 1) {
      this.loadingSubject.next(true);
    }
  }

  public onRequestFinished(method: string): void {
    // console.log('Request finished');
    this.requestCounter--;
    if (this.requestCounter === 0) {
      this.loadingSubject.next(false);
    }
  }
}
