import {
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CustomTransform } from '../../utils/CustomTransfom.interface';
import { IdGenerator } from '../../utils/IdGenerator';
import { Orientation, OrientationClass } from '../../utils/orientation';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractValueAccessor<T>
  implements ControlValueAccessor, OnInit
{
  @Output()
  public blurInput = new EventEmitter();

  @Output()
  public valueChange = new EventEmitter();

  @Input()
  public id = IdGenerator.randomId();

  @Input()
  public type = 'text';

  @Input()
  public label = '';

  @Input()
  public customTransformer: CustomTransform = {} as CustomTransform;

  @Input()
  public readonly = false;

  @Input()
  public orientation: Orientation = Orientation.vertical;

  // @HostBinding('class') @Input() public class = '';

  @Input()
  public set toUpperCase(value: boolean | string) {
    this.internalToUpperCase = `${value}` !== 'false';
  }

  public disabled = false;

  protected internalValue: T = {} as T;

  protected internalToUpperCase = false;

  protected validators: (ValidatorFn | null)[] = [];

  get value(): T {
    return this.getValue();
  }

  set value(value: T) {
    // console.log('+++ AbstractValueAccessor: set value : ', value);
    this.setValue(value);
  }

  get control(): AbstractControl | null {
    return this.controlDirective?.control;
  }

  constructor(@Self() @Optional() private controlDirective: NgControl) {
    if (this.controlDirective) {
      this.controlDirective.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    // Init validators
    if (this.control) {
      const validators: ValidatorFn[] = this.control.validator
        ? [this.control.validator]
        : [];
      const internalValidators = Validators.compose(this.validators);
      if (internalValidators) {
        validators.push(internalValidators);
      }
      validators.push(this.validate);
      // console.log('Validators : ', validators);
      this.control.setValidators(validators);
      this.control.updateValueAndValidity();
      // console.log('Validators : ', validators);
    }

    // Init class
    /*
    if (!this.class) {
      this.class =
        this.orientation === Orientation.vertical
          ? OrientationClass.vertical
          : OrientationClass.horizontal;
    }
    */
  }

  // extending control can't overwrite getter
  public getValue(): T {
    return this.internalValue;
  }

  // extending control can't overwrite setter
  public setValue(value: T): void {
    // console.log('Base set value : ', value);
    if (value !== this.internalValue) {
      // Not here - value transformed -> leads to errors
      // value = this.transformValue(value);
      this.internalValue = value;
      this.onChange(value);
      this.valueChange.emit(value);
    }
  }

  public writeValue(value: any): void {
    // console.log('Base write value : ', value);
    this.internalValue = value;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public transformValue(value: T): T {
    if (typeof value === 'string') {
      return (value && this.internalToUpperCase
        ? value.toUpperCase()
        : value) as unknown as T;
    }
  }

  public onBlur($event: any = null): void {
    this.onTouched();
    this.blurInput.emit();
  }

  public onChange = (_: any) => {};

  public onTouched = () => {};

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
