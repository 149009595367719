<app-control-wrapper [orientation]="orientation" [label]="label" [for]="id">
  <div class="d-flex">
    <app-control-error
      [showError]="(control?.invalid && control?.touched && control?.dirty) || false"
      [errors]="control?.errors"
    >
    </app-control-error>
    <div
      ngbDropdown
      id="id"
      #dropdown="ngbDropdown"
      placement="bottom-left"
      autoClose="outside"
      class="dropdown"
    >
      <div class="input-group flex-nowrap">
        <div id="button-wrapper" class="input-group-prepend">
          <button
            ngbDropdownToggle
            #dropdownButton
            class="btn btn-outline-secondary dropdown-button"
            type="button"
            (click)="openMenu()"
            (blur)="onBlur()"
            (change)="onChange(value)"
          >
            <!-- (keydown)="selectByKey($event)" -->
            <ng-container *ngIf="value && value.name">
              {{ value.name }}
            </ng-container>
            <ng-container *ngIf="value && !value.name">
              {{ value }}
            </ng-container>
          </button>
        </div>
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary icon icon-select"
            type="button"
            [ngClass]="this.dropdown.isOpen() ? 'icon-open' : ''"
          >
            <fa-icon
              [icon]="fasChevronDown"
              size="2x"
              (click)="$event.stopPropagation(); openMenu()"
            ></fa-icon>
          </button>
        </div>
      </div>
      <div id="button-menu-wrapper" ngbDropdownMenu class="dropdown-menu">
        <div
          ngbDropdownItem
          id="ddi-{{ $index }}"
          class="dropdown-button-item"
          [ngClass]="selected[$index] ? 'selected' : ''"
          (click)="select(item)"
          (blur)="onBlur()"
          *ngFor="let item of model; let $index = index"
        >
          <!-- (keydown)="selectByKey($event)" -->
          <div *ngIf="item && item.name" style="margin-left: -10px">
            {{ item.name }}
          </div>
          <div *ngIf="item && !item.name" style="margin-left: -10px">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</app-control-wrapper>
