import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Notification, NotificationType } from '../notification.interface';
import { NotificationService } from '../service/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  public notification: Notification = {} as Notification;

  public get notificationType(): any {
    return NotificationType;
  }

  private onDestroy$ = new Subject();

  constructor(
    private router: Router,
    private clipboard: Clipboard,
    private notificationService: NotificationService
  ) {
    this.router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        this.delete();
      }
    });
  }

  public ngOnInit(): void {
    this.notificationService.notificationSubject$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((notification) => {
        // console.log('### Error Notification : ', notification);
        this.notification = notification;
      });
  }

  public clipMessage(): void {
    console.log('Copy message to clipboard : ', this.notification);
    this.clipboard.copy(JSON.stringify(this.notification, null, 2));
  }

  public delete(): void {
    this.notification = {} as Notification;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next(null);
  }
}
