import { Component, Input, OnInit } from '@angular/core';
import { Orientation } from '../../utils/orientation';

@Component({
  selector: 'app-control-wrapper',
  templateUrl: './control-wrapper.component.html',
  styleUrls: ['./control-wrapper.component.scss'],
})
export class ControlWrapperComponent {
  @Input()
  public for = '';

  @Input()
  public label = '';

  @Input()
  public orientation = Orientation.vertical;

  @Input()
  public wrapInLabel = true;

  public get o(): any {
    return Orientation;
  }

  constructor() {}
}
