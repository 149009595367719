import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ContextMenuControlComponent } from './components/context-menu-control/context-menu-control.component';
import { ControlErrorComponent } from './components/control-error/control-error.component';
import { ControlWrapperComponent } from './components/control-wrapper/control-wrapper.component';
import { LoadingSpinnerComponent } from './components/loading/loading-spinner.component';
import { MenuComponent } from './components/menu/menu.component';
import { FormatCellPipe } from './components/table-control/format-cell.pipe';
import { TableControlComponent } from './components/table-control/table-control.component';
import { XAutocompleteComponent } from './controls/x-autocomplete/x-autocomplete.component';
import { DateTimeRangeComponent } from './controls/date-time-range/date-time-range.component';
import { DateTimeComponent } from './controls/date-time/date-time.component';
import { InputComponent } from './controls/input/input.component';
import { XMultiSelectSingleRowComponent } from './controls/x-multi-select-single-row/x-multi-select-single-row.component';
import { XMultiSelectComponent } from './controls/x-multi-select/x-multi-select.component';
import { XSelectComponent } from './controls/x-select/x-select.component';
import { TextComponent } from './controls/text/text.component';
import { InputFormatterDirective } from './directive/input-formatter.directive';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { SelectComponent } from './controls/select/select.component';
import { InputFieldDirective } from './directive/input-field.directive';
import { ObjectToSelectItemTransformerPipe } from './pipes/object-to-select-item-transformer.pipe';
import { MultiSelectMaterialComponent } from './controls/multi-select-material/multi-select-material.component';
import { SelectMaterialComponent } from './controls/select-material/select-material.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AutocompleteCdkComponent } from './controls/autocomplete-cdk/autocomplete-cdk.component';
import { AutocompleteDirective } from "./controls/autocomplete-cdk/autocomplete.directive";
import { AutocompleteContentDirective} from "./controls/autocomplete-cdk/autocomplete-content.directive";
import { OptionComponent} from "./controls/autocomplete-cdk/option/option.component";
import { FilterPipe } from "./controls/autocomplete-cdk/filter.pipe";

const components: any[] = [
  ConfirmDialogComponent,
  ControlErrorComponent,
  ControlWrapperComponent,
  TableControlComponent,
  FormatCellPipe,
  ContextMenuControlComponent,
  XSelectComponent,
  TextComponent,
  InputComponent,
  XMultiSelectComponent,
  XMultiSelectSingleRowComponent,
  DateTimeComponent,
  DateTimeRangeComponent,
  LoadingSpinnerComponent,
  XAutocompleteComponent,
  MenuComponent,
  SelectComponent,
  SelectMaterialComponent,
  MultiSelectMaterialComponent,

  AutocompleteCdkComponent,
  AutocompleteDirective,
  AutocompleteContentDirective,
  OptionComponent,
  FilterPipe
];

const directives: any[] = [InputFormatterDirective, InputFieldDirective];

const pipes: any[] = [CustomCurrencyPipe];

// Add to imports: FontAwesomeModule, AutosizeModule

// EntryComponents is any component that is loaded by its class, not selector.
@NgModule({
  declarations: [
    components,
    directives,
    pipes,
    ObjectToSelectItemTransformerPipe,
  ],
  exports: [components, directives, pipes],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OverlayModule,
    NgbModule,
    FontAwesomeModule,
    RouterModule,
    DropdownModule,
    TooltipModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatAutocompleteModule,
  ],
  providers: [],
})
export class FeatSharedModule {}
