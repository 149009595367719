<app-control-wrapper [orientation]="orientation" [label]="label" [for]="id">
  <div class="d-flex">
    <app-control-error
      [showError]="(control?.invalid && control?.touched && control?.dirty) || false"
      [errors]="control?.errors"
    >
    </app-control-error>
    <div class="input-group">
      <input
        [id]="id"
        type="datetime-local"
        autocomplete="off"
        class="form-control"
        spellcheck="false"
        [class.is-invalid]="control?.invalid && control?.touched && control?.dirty"
        [maxlength]="maxLength"
        [readOnly]="readonly"
        [disabled]="disabled"
        (blur)="onBlur()"
        (change)="onChange(value)"
        [(ngModel)]="value"
      />
    </div>
  </div>
</app-control-wrapper>
