import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Validator } from '@angular/forms';
import { Dropdownable } from '../../interfaces/dropdown.interface';
// import {faCaretSquareDown as farCaretSquareDown} from '@fortawesome/free-regular-svg-icons';
// import {faCaretSquareDown as fasCaretSquareDown} from '@fortawesome/free-solid-svg-icons';
import { faChevronDown as fasChevronDown } from '@fortawesome/free-solid-svg-icons';
// import {faChevronDown as farChevronDown} from '@fortawesome/free-regular-svg-icons';
import { AbstractValueAccessor } from '../base/abstract-value-accessor';
// import scrollIntoView from 'scroll-into-view-if-needed';

@Component({
  selector: 'app-x-select',
  templateUrl: './x-select.component.html',
  styleUrls: ['./x-select.component.scss'],
  providers: [],
})
export class XSelectComponent
  extends AbstractValueAccessor<Dropdownable>
  implements OnInit, Validator
{
  @Input()
  public model: Dropdownable[] = [];

  @ViewChild('dropdown') public dropdown: any;

  @ViewChild('dropdownButton') public dropdownButton: ElementRef =
    {} as ElementRef;

  public selected: Array<boolean> = [];
  public preselectedItem = '';
  public arrowPosition = 0;
  public firstItem = true;
  public errors = false;

  // @HostBinding('class') @Input() public override class: any;

  // public fasCaretSquareDown = fasCaretSquareDown;
  // public farCaretSquareDown = farCaretSquareDown;
  public fasChevronDown = fasChevronDown;

  /*
      constructor(public controlDirective: NgControl) {
        super(controlDirective);
      }
    */

  public override ngOnInit(): void {
    super.ngOnInit();
    this.selected = new Array<boolean>();
  }

  public openMenu(): void {
    for (let i = 0; i < this.model.length; i++) {
      this.selected.push(false);
    }

    if (this.dropdown) {
      this.dropdown.open();
      this.dropdownButton.nativeElement.focus();
      this.showItem();
    }
  }

  public closeMenu(): void {
    this.dropdown.close();
    this.onTouched();
  }

  public select(selected: Dropdownable): void {
    super.setValue(selected);
    this.closeMenu();
    this.onTouched();
  }

  /*
  public selectByKey($event: KeyboardEvent): void {
    const key = $event.key;
    console.log('Key : ', key);
    if (key === 'Tab') {
      this.closeMenu();
    } else if (key === 'ArrowUp') {
      this.selected[this.arrowPosition] = false;
      this.arrowPosition--;
      if (this.arrowPosition < 0) {
        this.arrowPosition = this.model.length - 1;
      }
      this.selected[this.arrowPosition] = true;
      this.preselectedItem = this.model[this.arrowPosition];
      setTimeout(() => {
        this.updateView('ddi-' + this.arrowPosition);
      }, 10);
    } else if (key === 'ArrowDown') {
      this.selected[this.arrowPosition] = false;
      if (!this.firstItem) {
        this.arrowPosition++;
      }
      if (this.arrowPosition >= this.model.length) {
        this.arrowPosition = 0;
      }
      this.selected[this.arrowPosition] = true;
      this.preselectedItem = this.model[this.arrowPosition];
      this.firstItem = false;
      setTimeout(() => {
        this.updateView('ddi-' + this.arrowPosition);
      }, 10);
    } else if (key !== 'Enter') {
      this.selected.fill(false);
      for (let i = 0; i < this.model.length; i++) {
        const item = this.model[i];
        if (item.toLowerCase().startsWith(key.toLowerCase())) {
          this.selected[i] = true;
          this.preselectedItem = item;
          this.arrowPosition = i;
          this.firstItem = false;
          this.updateView('ddi-' + i);
          break;
        }
      }
    } else {
      // Enter key
      super.setValue(this.preselectedItem);
      this.closeMenu();
      this.reset();
    }

  }
*/

  private showItem(): void {
    const item = super.getValue();
    if (item !== null) {
      this.selected.fill(false);
      for (let i = 0; i < this.model.length; i++) {
        if (item === this.model[i]) {
          this.arrowPosition = i;
          this.selected[this.arrowPosition] = true;
          setTimeout(() => {
            this.updateView('ddi-' + this.arrowPosition);
          }, 10);
        }
      }
    }
  }

  private updateView(id: string): void {
    /*
        scrollIntoView(document.getElementById(id), {
          scrollMode: 'if-needed',
          block: 'nearest',
          inline: 'nearest'
        });
    */
  }

  /*
  private reset(): void {
    this.selected.fill(false);
    this.arrowPosition = 0;
    this.firstItem = false;
  }
  */

  /*
    public validate(c: AbstractControl): ValidationErrors | null {
      console.log('Validate : ', c.value);
      const hasError = false;

      let errorRet: { inputError: { valid: boolean; messages: string[] } };
      errorRet = {
        inputError: {
          valid: false,
          messages: []
        }
      };

      const value = c.value;
      if (value.length >= 9) {
        errorRet.inputError.valid = false;
        errorRet.inputError.messages = ['error to long'];
      }

      return hasError ? errorRet : null;
    }
  */
}
