<div class="multi-select-material" style="background-color: white">
  <form class="example-form">
  <p style="font-family: Helvetica Neue, Helvetica, Arial, sans-serif; font-weight: bold">Multi Autocomplete</p>

<!--
  Data:<br>
  {{ data }}<br><br>
-->

    <mat-form-field class="example-full-width" color="primary" appearance="fill">
      <div style="display: flex;">  <!--  border: 1px solid red" -->
        <div style="margin-right: 10px; padding-left: 5px;">
          <mat-label style="">All</mat-label>
          <br>
          <mat-checkbox [formControl]="allCheckboxControl"
                        [ngModel]="allCheckboxValue"
                        (change)="toggleSelectAll()"
                        (click)="$event.stopPropagation()"
                        style="display: flex; justify-content: center; width: 92%; padding-top: 1px;"
                        aria-label="Select All Items">
          </mat-checkbox>
          <br>
        </div>
        <div>
          <mat-label class="mat-primary">Category</mat-label>
          <div #daic class="placeholder" data-placeholder="">
          <input #aic
                 matInput
                 type="text"
                 [formControl]="autocompleteInputControl"
                 [matAutocomplete]="auto"
                 [ngModel]="autocompleteInputValue"
                 (focus)="onFocus()"
                 style="background-color: white;"
                 aria-label="Category">
          </div>
          <mat-autocomplete #auto="matAutocomplete"
                            (closed)="onClose()"
                            multiple>
            <mat-option *ngFor="let item of filteredData | async, let i = index"
                        [value]="item"
                        style="padding-left: 17px"
                        >
              <div (click)="optionClicked($event, item)">
                <mat-checkbox [checked]="dataSelected[i]"
                              (change)="toggleSelection(item)"
                              (click)="$event.stopPropagation()">
                  {{ item }}
                </mat-checkbox>
              </div>
            </mat-option>
          </mat-autocomplete>
        </div>
        <div style="margin-left: auto; margin-right: 5px">
          <mat-label></mat-label>
          <br>
          <button mat-button color="primary" style="margin-top: -1px; max-height: 20px; max-width: 22px; padding-left: 3px;">AI</button>
        </div>
      </div>
    </mat-form-field>

    <!--
      <mat-select-trigger>
        <mat-chip-list style="margin: 10px">
          <mat-chip *ngFor="let item of autocompleteInputControl.value"
                    [removable]="true" (removed)="onRemoved(item)" color="accent" selected>
            {{ item }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </mat-select-trigger>
    -->

  </form>
</div>

