<div
  *ngIf="(notification | json) !== '{}'"
  class="container-fluid notes-panel mb-1"
  [ngClass]="notification.status ? 'alert-danger' : 'alert-success'"
>
  <div class="clearfix">
    <div
      *ngIf="notification.type === notificationType.error"
      class="float-left col-form-label ml-2"
      (dblclick)="clipMessage()"
    >
      Code : {{ notification.status }} <span>&nbsp;&nbsp;</span> Message : {{ notification.statusText }}
    </div>
    <div
      *ngIf="notification.type === notificationType.success"
      class="float-left col-form-label ml-2"
      (dblclick)="clipMessage()"
    >
      Info : {{ notification.name }} <span>&nbsp;&nbsp;</span> Message : {{ notification.message }}
    </div>
    <div class="float-right btn" (click)="delete()">
      <span>&times;</span>
    </div>
  </div>
</div>
