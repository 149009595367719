import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  NotificationService,
  NotificationType,
} from '@calendar/feat-notification';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public errorMap: Map<number, string> = new Map([
    [500, 'Internal Server Error'],
  ]);

  constructor(
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        console.log('Interceptor error : ', err);
        if (err.status === 0) {
          // Unknown Error - e.g. backend down / server not responding
          this.notificationService.notify({
            type: NotificationType.error,
            status: err.status,
            statusText: 'Unknown Server Error! (maybe server down?)',
            url: err.url ?? undefined,
            name: err.name,
            message: err.message,
            serverError: err.error?.objResponse,
          });
          const error = err.error.error || err.error.message || err.statusText;
          throwError(error);
          return EMPTY;
        } else if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authService.logout();
          // location.reload();
        } else {
          // if (err.status === 500) {
          this.notificationService.notify({
            type: NotificationType.error,
            status: err.status,
            statusText:
              err.status === 0 ? err.statusText : this.errorMap.get(err.status),
            url: err.url ?? undefined,
            name: err.name,
            message: err.message,
            serverError: err.error?.objResponse,
          });
        }

        const error = err.error.error || err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}

export const errorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
