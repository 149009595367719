export interface Notification {
  type: NotificationType;
  status?: number;
  statusText?: string;
  url?: string;
  name?: string;
  message?: string;
  serverError?: any;
}

export enum NotificationType {
  error,
  success = 1,
}
