import { QueryData } from './context-menu.interface';
import { Dropdownable } from './dropdown.interface';

// TODO: Due to the type of TableItem - id, created, title and text are now optional - bad solution
export interface Note {
  id: number;
  // TODO : Use Moment interface from moment package - not Date
  created: Date;
  lastModified?: Date;
  title: string;
  text: string;
  category?: Category;
  priority?: Priority;
  selected?: boolean;
}

export interface NotesQueryData extends QueryData {
  title: string;
  category?: Category;
  priority?: Priority;
}

export interface Category extends Dropdownable {
  id: number;
  // TODO : Use Moment interface from moment package - not Date
  created?: Date;
  lastModified?: Date;
  position?: number;
  // name: string;
}

export enum Priority {
  low = 'Low',
  normal = 'Normal',
  high = 'High',
  urgent = 'Urgent',
}
