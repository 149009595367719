import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@calendar/feat-auth';
import { AppState, logout, selectUser, selectUserState, User, UserState } from '@calendar/feat-shared';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelpService } from '../help.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  public title = '';

  public userState$: Observable<UserState>;

  public user$: Observable<User | null>;

  public onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private helpService: HelpService,
    private store: Store<AppState>
  ) {}

  public ngOnInit() {
    // takeUntil ... not needed due to usage of async pipe
    this.userState$ = this.store.select(selectUserState); // .pipe(takeUntil(this.onDestroy$));
    this.user$ = this.store.select(selectUser); // .pipe(takeUntil(this.onDestroy$));
  }

  public help(): void {
    console.log('Url : ', this.router.url);
    // test only - window.open('assets/help.html', '_self');
    const url = this.helpService.getCurrentHelpTopic(this.router.url);
    window.open(url);
  }

  public register(): void {
    console.log('Register');
    this.router.navigate(['register']);
  }

  public logout(): void {
    this.authService.logout();
    this.store.dispatch(logout());
  }

  public userLoggedIn(): boolean {
    return this.authService.currentUserValue;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
