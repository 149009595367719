import {
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ControlErrors } from '../../components/control-error/control-error.interface';
import { InputFieldDirective } from '../../directive/input-field.directive';
import { CustomTransform } from '../../utils/CustomTransfom.interface';
import { IdGenerator } from '../../utils/IdGenerator';
import { Orientation, OrientationClass } from '../../utils/orientation';

// const trimOnBlurCustomInputTransformer = new TrimOnBlurCustomInputTransformer();

type TransformerFn = (inputValue: string) => string;
// Map all validation functions.
// Result: they return a modified value
// TODO: uncomment and fix error
/*
export const createValueAccessorProxy = (
  validatorFns: ValidatorFn[],
  transformers: TransformerFn[]
): ValidatorFn[] => {
  return validatorFns.map((validatorFn: ValidatorFn) => {
    return (c: AbstractControl): ControlErrors | null => {
      return validatorFn(
        new Proxy(c, {
          get(target: AbstractControl, p: string | number | symbol): any {
            const originalValue = target[p];
            if (p === 'value' && typeof originalValue === 'string') {
              return transformers.reduce((value, transformer) => transformer(value), originalValue);
            }
          },
        })
      );
    };
  });
};
*/

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractValueAccessor2<T>
  implements ControlValueAccessor, OnInit
{
  @Output()
  public blurInput = new EventEmitter();

  @Output()
  public valueChange = new EventEmitter();

  @Input()
  public id = IdGenerator.randomId();

  /*
  @Input()
  public type = 'text';
  */

  @Input()
  public label: string | undefined = '';

  @Input()
  public readonly = false;

  @Input()
  public orientation: Orientation = Orientation.vertical;

  @ViewChild(InputFieldDirective, { static: false })
  public inputField: InputFieldDirective;

  // @HostBinding('class') @Input() public class = '';

  @Input()
  public set toUpperCase(value: boolean | string) {
    this.internalToUpperCase = `${value}` !== 'false';
  }

  @Input()
  public trim = true;

  /*
  @Input()
  public errorIsHandledByParentControl = false;
  */

  /*
  // Concrete impl. must use appCustomInputTransformer directive on html element
  @Input()
  public customInputTransformers: CustomInputTransformers[] = [];
  */

  /*
  @Input()
  public customOnBlurInputTransformers: OnBlurCustomInputTransformers[] = [];
  */

  public disabled = false;

  protected internalValue: T = {} as T;

  protected internalToUpperCase = false;

  protected validators: (ValidatorFn | null)[] = [];

  get value(): T {
    return this.getValue();
  }

  set value(value: T) {
    this.setValue(value);
  }

  get control(): AbstractControl | null {
    return this.controlDirective?.control;
  }

  protected constructor(
    @Self() @Optional() private controlDirective: NgControl
  ) {
    // console.log('### AbstractValueAccessor2 : ', controlDirective);
    if (this.controlDirective) {
      // console.log('### AbstractValueAccessor2 : init valueAccessor');
      this.controlDirective.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    // this.initClass(); - lines below in a function
    // this.initValidators(); - lines below in a function
    // this.initId();
    // this.initTrimBehaviour(); - todo ...

    // Init validators
    if (this.control) {
      const validators: ValidatorFn[] = this.control.validator
        ? [this.control.validator]
        : [];
      const internalValidators = Validators.compose(this.validators);
      if (internalValidators) {
        validators.push(internalValidators);
      }
      validators.push(this.validate);
      // console.log('Validators : ', validators);
      this.control.setValidators(validators);

      // Only validate after initialization of th e validators
      setTimeout(() => {
        this.control?.updateValueAndValidity();
      });
      // console.log('Validators : ', validators);

      /*
      const transformerFns = [];
      if (this.trim) {
        transformerFns.push((value: string) => value.trim());
      }

      this.control.setValidators(
        transformerFns.length > 0 ? createValueAccessorProxy(validators, transformerFns) : validators
      );
       */
    }

    // Init class
    /*
    if (!this.class) {
      this.class =
        this.orientation === Orientation.vertical
          ? OrientationClass.vertical
          : OrientationClass.horizontal;
    }
*/

    /* todo
    setTimeout(() => {
      this.control?.updateValueAndValidity();
    });
    */
  }

  // extending control can't overwrite getter
  public getValue(): T {
    return this.internalValue;
  }

  // extending control can't overwrite setter
  public setValue(value: T): void {
    // console.log('Base set value : ', value);
    if (value !== this.internalValue) {
      // Not here - value transformed -> leads to errors
      // value = this.transformValue(value);
      this.internalValue = value;
      this.onChange(value);
      this.valueChange.emit(value);
    }
  }

  public writeValue(value: any): void {
    // console.log('Base write value : ', value);
    this.internalValue = value;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public transformValue(value: T): T {
    if (typeof value === 'string') {
      return (value && this.internalToUpperCase
        ? value.toUpperCase()
        : value) as unknown as T;
    }
  }

  public forceFocus(): void {
    if (this.inputField) {
      setTimeout(() => {
        if (this.inputField.appInputField === 'click') {
          this.inputField.el.nativeElement.click();
        } else {
          this.inputField.el.nativeElement.focus();
        }
      }, 2);
    }
  }

  public onBlur($event: any = null): void {
    this.onTouched();
    this.blurInput.emit();
  }

  public onChange = (_: any) => {};

  public onTouched = () => {};

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
