import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Dropdownable, DropdownData } from '../interfaces/dropdown.interface';
import { API_URL_PREFIX } from '../injector/token';

@Injectable({
  providedIn: 'root',
})
export class DropdownDataService {
  // TODO : Implement cache
  // public cache: Map<string, Array<Dropdownable>> = new Map();

  constructor(@Inject(API_URL_PREFIX) private apiUrlPrefix: string, private httpClient: HttpClient) {}

  public getDropdownData(
    dropdownDataArray: Array<DropdownData<Dropdownable>>
  ): Observable<Array<DropdownData<Dropdownable>>> {
    // Check whether data in cache already before building requests
    // if (!this.cache.get(dropdownDataArray[index].name)) {
    // }
    const requests = dropdownDataArray.map((entry) => {
      return this.httpClient.get<Array<Dropdownable>>(this.apiUrlPrefix + entry.url + '/all');
    });
    // console.log(new Date() + ' Before forkJoin');
    return forkJoin(requests).pipe(
      map((responses) => {
        responses.forEach((response, index) => {
          dropdownDataArray[index].dropdown = response;
        });
        // console.log(new Date() + ' End forkJoin');
        return dropdownDataArray;
      })
    );
  }
}
