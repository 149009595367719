import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public messageSubject: BehaviorSubject<string> = new BehaviorSubject<string>('empty');
  public messageSubjectObservable: Observable<string> = this.messageSubject.asObservable();

  constructor() {} // private ngbModal: NgbModal) { }

  public notify(message: string): void {
    console.log('Notification message : ', message);
    this.messageSubject.next(message);

    /*
    const modal = this.ngbModal.open(NotificationPopupComponent, { backdrop: 'static' });
    modal.componentInstance.currentMessage = message;
*/
  }
}
