import {Component, Input, OnInit, Optional, Self, ViewChild} from '@angular/core';
import {AbstractValueAccessor2} from "../base/abstract-value-accessor2";
import {Dropdownable, PDropdownable} from "../../interfaces/dropdown.interface";
import {NgControl, Validator} from "@angular/forms";
import {Dropdown} from "primeng/dropdown";
import {ObjectToSelectItemTransformerFn} from "../../pipes/object-to-select-item-transformer.pipe";

@Component({
  selector: 'app-select-material',
  templateUrl: './select-material.component.html',
  styleUrls: ['./select-material.component.scss'],
})
export class SelectMaterialComponent extends AbstractValueAccessor2<Dropdownable> implements OnInit, Validator {
  /*
  @Input()
  public model: Dropdownable[] = [];
  */

  public emptyLabel = '---';
  public emptyValue = -1;

  // PDropdownable means : label, value pair (value e.g. object)
  public internalData: Dropdownable[] = [];

/*
  get selectedValue() {
    return this.value;
  }
  set selectedValue(sv: Dropdownable) {
    this.value = sv;
  }
*/

  @ViewChild(Dropdown) public dropdown: Dropdown;

  private open = false;

  /**
   * p-dorpdown expects an option list with the format { label: string, value: any }
   *
   * @param value: PDropdownable
   */
  @Input()
  public set data(value: any[] | undefined) {
    this.internalData = [];
    // this.internalData = value || [];
    value?.forEach((item) => {
      // TODO : enum, label, name, ...
      this.internalData.push({id: item.id, label: item.name, name: item.name});
/*
      if (
        Object.prototype.hasOwnProperty.call(item, 'label') &&
        Object.prototype.hasOwnProperty.call(item, 'value')
      ) {
        this.internalData.push({label: item.name, value: item.name});
      } else {
        // PDropdownable
        this.internalData.push({label: item.label || item.name, value: item});
      }
*/
    });
  }


  @Input()
  public set dataWithEmptyOption(value: any[] | undefined) {
    if (!value?.includes(null)) {
      value?.unshift(null);
    }
    this.internalData = value || [];
  }

  @Input()
  public objectToSelectItemTransformerFn: ObjectToSelectItemTransformerFn = (o: any) => o;

  /* TODO: Transformation already part of set data(...)
      @Input()
      public objectToSelectItemTransformerFn: ObjectToSelectItemTransformerFn = (item: any) => {
        if (
          Object.prototype.hasOwnProperty.call(item, 'label') &&
          Object.prototype.hasOwnProperty.call(item, 'name')
        ) {
          return { label: item.label, value: item.name };
        } else {
          // PDropdownable
          return { label: item.label || item.name, value: item };
        }
      };
   */

  public get name() {
    console.log('Name : ', this.value?.name ?? 'Select Item');
    return (this.value?.label || this.value?.name) ?? 'Select Item';
  }

  constructor(@Optional() @Self() public ngControl: NgControl) {
    super(ngControl);
  }

  public onKeyDown($event: any): void {
    if ($event?.key === ' ' && this.open) {
      this.dropdown.hide();
      $event.preventDefault();
      this.open = false;
    }
  }

  public onFocus(): void {
    this.dropdown.focus();
  }

  public onShow(): void {
    setTimeout(() => {
      this.open = true;
    }, 0);
  }

  public onHide(): void {
    this.open = false;
  }

}
