<ng-container [formGroup]="form">
  <div class="row">
    <!-- events only if inner component -->
    <app-date-time
      formControlName="from"
      ngDefaultControl
      [label]="labelFrom"
      [class]="columnSize.range"
      [orientation]="orientation"
      [readonly]="readonly"
      (valueChange)="onValueChange()"
      (blurInput)="onBlur()"
    >
    </app-date-time>
    <app-date-time
      formControlName="to"
      ngDefaultControl
      [label]="labelTo"
      [class]="columnSize.range"
      [orientation]="orientation"
      [readonly]="readonly"
      (valueChange)="onValueChange()"
      (blurInput)="onBlur()"
    >
    </app-date-time>
  </div>
</ng-container>
