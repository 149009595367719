<app-control-wrapper [orientation]="orientation" [label]="label" [for]="id">
  <div class="d-flex">
    <app-control-error
      [showError]="(control?.invalid && control?.touched && control?.dirty) || false"
      [errors]="control?.errors"
    >
    </app-control-error>

    <!-- select all / unselect all button -->
    <!-- autocomplete input -->
    <div class="input-group">
      <input
        [id]="id"
        type="text"
        autocomplete="off"
        class="form-control"
        spellcheck="false"
        [class.is-invalid]="control?.invalid && (control?.touched || control?.dirty)"
        [readOnly]="true"
        [disabled]="disabled"
        (blur)="onBlur()"
        (click)="toggleVisibility()"
        [value]="itemsSelected()"
      />
    </div>
    <!-- icon -->
  </div>
  <!-- popup -->
  <div [hidden]="!visible" class="container-fluid" style="background-color: cyan">
    <div class="row">
      <div class="col-auto radio" style="background-color: red">
        <label for="selectAll" class="radio-inline">
          <input id="selectAll" type="checkbox" [checked]="selectAll" (click)="toggleSelectAll()" />
          Select All
        </label>
      </div>
      <div class="col" style="background-color: green">
        <input
          id="inputId"
          #instance="ngbTypeahead"
          #instanceRef
          [ngbTypeahead]="search"
          [resultTemplate]="resultTemplate"
          [inputFormatter]="formatter"
          (selectItem)="selectItem($event)"
          type="text"
          autocomplete="off"
          class="form-control col"
          spellcheck="false"
          [class.is-invalid]="control?.invalid && (control?.touched || control?.dirty)"
          [readOnly]="readonly"
          [disabled]="disabled"
          (blur)="onBlur()"
        />
        <!-- [(ngModel)]="model" -->
        <!--
        (focus)="focus$.next($event.target.value)"
        (click)="click$.next($event.target.value)"
-->
      </div>
      <div class="col-auto" style="background-color: yellow">
        <input type="button" class="form-control btn-primary" value="Clear" (click)="clearInput()" />
      </div>
    </div>
    <!-- icon -->
  </div>
  <!--
    <div>
      <button *ngFor="let item of data; let $index=index"
        (click)="select(item, $index)" class="form-control" type="button">
          <input type="checkbox" [checked]="selected[$index] ? 'checked' : ''">
          <input type="button" [value]="item">
      </button>
    </div>
  -->
</app-control-wrapper>

<ng-template #resultTemplate let-r="result" let-t="term">
  <div class="form-control" (click)="toggleSelectItem(r.name, $event)">
    <input type="checkbox" [checked]="r.checked ? 'checked' : ''" />
    <input type="button" style="border: 0" [value]="r.name" />
  </div>
</ng-template>
