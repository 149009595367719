import { createAction, props } from '@ngrx/store';
import { User } from '../../interfaces/user.interface';
import { UserState } from '../models/userState';

export enum UserActionTypes {
  LOGIN_SUCCESS = '[User] Login Success',
  LOGIN_FAILED = '[User] Login Failed',
  LOGOUT = '[User] Logout',
}

export const loginSuccess = createAction(UserActionTypes.LOGIN_SUCCESS, props<{ payload: User }>());
export const loginFailed = createAction(UserActionTypes.LOGIN_FAILED, props<{ error: string }>());
export const logout = createAction(UserActionTypes.LOGOUT);
