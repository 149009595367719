// type NonOptionalKeys<T> = { [K in keyof T]-?: T extends { [K1 in K]: any } ? K : never }[keyof T];
// type Keys<T> = { [K in keyof T]-?: T extends { [K1 in K]: any } ? K : K }[keyof T];
// type Keys<T> = { [K in keyof T]: T extends { [L in K]: any } ? K : K }[keyof T]; // works!!!
type AllKeysOf<T> = { [K in keyof T]: T extends { [L in K]: any } ? K : K }[keyof T]; // works!!!

export interface TableItem<T> {
  // = { [key: string]: any }> {
  // Header
  header: string;
  // Row data
  attribute: AllKeysOf<T> & string; // keyof T & string;
  path?: string;
  // width?: ColumnWidth ('cell-width-1' ...)
  format?: string;
  formatOptions?: {
    currencyCode?: string;
    timezone?: string;
  };
  isEditable?: boolean;
  isSortable?: boolean;
  isSortDefault?: boolean;
  // Used if user can choose default visible columms
  isVisibleByDefault?: boolean;
  // isVisibleIfOtherColumnContentEqualsValue?: {
  //   attribute: string;
  //   value: string;
  // };
}
