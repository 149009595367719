import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { NgControl, Validator } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
import { Dropdownable, PDropdownable } from '../../interfaces/dropdown.interface';
// import {faCaretSquareDown as farCaretSquareDown} from '@fortawesome/free-regular-svg-icons';
// import {faCaretSquareDown as fasCaretSquareDown} from '@fortawesome/free-solid-svg-icons';
import { faChevronDown as fasChevronDown } from '@fortawesome/free-solid-svg-icons';
// import {faChevronDown as farChevronDown} from '@fortawesome/free-regular-svg-icons';
// import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
// import { AbstractValueAccessor } from '../base/abstract-value-accessor';
import { AbstractValueAccessor2 } from '../base/abstract-value-accessor2';
import { SelectItem } from 'primeng/api';
import { ObjectToSelectItemTransformerFn } from '../../pipes/object-to-select-item-transformer.pipe';
// import scrollIntoView from 'scroll-into-view-if-needed';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends AbstractValueAccessor2<Dropdownable> implements OnInit, Validator {
  /*
  @Input()
  public model: Dropdownable[] = [];
  */

  public emptyLabel = '---';
  public emptyValue = -1;

  // PDropdownable means : label, value pair (value e.g. object)
  public internalData: PDropdownable[] = [];

  @ViewChild(Dropdown) public dropdown: Dropdown;

  private open = false;

  /**
   * p-dorpdown expects an option list with the format { label: string, value: any }
   *
   * @param value: PDropdownable
   */
  @Input()
  public set data(value: any[] | undefined) {
    this.internalData = [];
    // this.internalData = value || [];
    value?.forEach((item) => {
      // TODO : enum, label, name, ...
      if (
        Object.prototype.hasOwnProperty.call(item, 'label') &&
        Object.prototype.hasOwnProperty.call(item, 'name')
      ) {
        this.internalData.push({ label: item.label, value: item.name });
      } else {
        // PDropdownable
        this.internalData.push({ label: item.label || item.name, value: item });
      }
    });
  }

  @Input()
  public set dataWithEmptyOption(value: any[] | undefined) {
    if (!value?.includes(null)) {
      value?.unshift(null);
    }
    this.internalData = value || [];
  }

  @Input()
  public objectToSelectItemTransformerFn: ObjectToSelectItemTransformerFn = (o: any) => o;
  /* TODO: Transformation already part of set data(...)
      @Input()
      public objectToSelectItemTransformerFn: ObjectToSelectItemTransformerFn = (item: any) => {
        if (
          Object.prototype.hasOwnProperty.call(item, 'label') &&
          Object.prototype.hasOwnProperty.call(item, 'name')
        ) {
          return { label: item.label, value: item.name };
        } else {
          // PDropdownable
          return { label: item.label || item.name, value: item };
        }
      };
   */

  public get name() {
    console.log('Name : ', this.value?.name ?? 'Select Item');
    return (this.value?.label || this.value?.name) ?? 'Select Item';
  }

  constructor(controlDirective: NgControl) {
    super(controlDirective);
  }

  public onKeyDown($event: any): void {
    if ($event?.key === ' ' && this.open) {
      this.dropdown.hide();
      $event.preventDefault();
      this.open = false;
    }
  }

  public onFocus(): void {
    this.dropdown.focus();
  }

  public onShow(): void {
    setTimeout(() => {
      this.open = true;
    }, 0);
  }

  public onHide(): void {
    this.open = false;
  }
}
