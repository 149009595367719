<div class="row">
  <div class="col-12">
    <div class="row mt-1 mb-1">
      <div class="container-fluid text-right">
        <button type="button" class="btn btn-link" (click)="navigate('calendar'); $event.preventDefault()">
          Calendar
        </button>
        <button type="button" class="btn btn-link" (click)="navigate('categories'); $event.preventDefault()">
          Categories
        </button>
        <button type="submit" class="btn btn-link ml-1" (click)="navigate('notes'); $event.preventDefault()">
          Notes
        </button>
      </div>
    </div>
  </div>
</div>
