<div class="modal-header">
  <h3 class="modal-title" id="modal-title">{{ config.headline }}</h3>
  <button
    type="button"
    class="close"
    aria-label="Close"
    *ngIf="config.dialogType !== dialogType.confirm"
  >
    <!--
    <fa-icon [icon]="icons.modalClose" size="1x"></fa-icon>
    -->
  </button>
</div>

<ng-container [ngSwitch]="config.dialogType">
  <form novalidate [formGroup]="modalForm" (keydown.enter)="$event.preventDefault()">
    <div class="modal-body">
      <ng-container *ngSwitchCase="dialogType.confirm">
        <p *ngIf="messagePrepend">{{ messagePrepend }}</p>
        <p *ngIf="message">{{ message }}</p>
        <ng-container [ngTemplateOutlet]="listTable"></ng-container>
        <p *ngIf="messageAppend">{{ messageAppend }}</p>
      </ng-container>
    </div>
  </form>
</ng-container>

<div class="modal-footer">
  <div class="row">
    <div class="col-md-auto">
      <button type="button" class="btn btn-primary" (click)="onClickConfirm()">
        {{ config.buttonConfirm }}
      </button>
    </div>
    <div class="col-md-auto">
      <button type="button" class="btn btn-primary" (click)="onClickRevoke()">
        {{ config.buttonRevoke }}
      </button>
      <button type="button" class="btn btn-primary" *ngIf="config?.buttonReset" (click)="onClickReset()">
        {{ config.buttonReset }}
      </button>
    </div>
  </div>
</div>

<ng-template #listTable>
  <!--
  <div class="mb-3" *ngIf="itemDetails?.rowData?.length > 0">
    <app-table-control [attributes]="itemDetails?.tableItems"
                       [data]="itemDetails?.rowData"> &lt;!&ndash; visibleColumns, allowSelection, allowExport, allowRefresh, pagination, filterEmptyColumns &ndash;&gt;
    </app-table-control>
  </div>
-->
</ng-template>
