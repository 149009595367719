import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import {
  ConnectionPositionPair,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { AutocompleteCdkComponent } from './autocomplete-cdk.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { filter, takeUntil } from 'rxjs/operators';
import { NgControl } from '@angular/forms';
// import { untilDestroyed } from 'ngx-take-until-destroy';

@Directive({
  selector: '[appAutocompleteComponentViaDirectiveInput]',
})
export class AutocompleteDirective implements OnInit {
  @Input() appAutocompleteComponentViaDirectiveInput: AutocompleteCdkComponent;
  private overlayRef: OverlayRef;

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private ngControl: NgControl,
    private vcr: ViewContainerRef,
    private overlay: Overlay
  ) {
    console.log('Host : ', host);
    console.log('NgControl : ', ngControl);
    console.log('ViewContainerRef : ', vcr);
    console.log('Overlay : ', overlay);
  }

  get control() {
    return this.ngControl.control;
  }

  ngOnInit() {
    fromEvent(this.origin, 'focus')
      .pipe
      //      untilDestroyed(this)
      ()
      .subscribe(() => {
        this.openDropdown();

        this.appAutocompleteComponentViaDirectiveInput
          .optionsClick()
          .pipe(takeUntil(this.overlayRef.detachments()))
          .subscribe((value: any) => {
            this.control?.setValue(value);
            this.close();
          });
      });
  }

  openDropdown() {
    this.overlayRef = this.overlay.create({
      width: this.origin.offsetWidth,
      maxHeight: 40 * 3,
      backdropClass: '',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.getOverlayPosition(),
    });

    const template = new TemplatePortal(
      this.appAutocompleteComponentViaDirectiveInput.rootTemplate,
      this.vcr
    );
    this.overlayRef.attach(template);

    overlayClickOutside(this.overlayRef, this.origin).subscribe(() =>
      this.close()
    );
  }

  //  ngOnDestroy() {}

  private close() {
    this.overlayRef.detach();
    // this.overlayRef = null;
  }

  private getOverlayPosition() {
    const positions = [
      new ConnectionPositionPair(
        { originX: 'start', originY: 'bottom' },
        { overlayX: 'start', overlayY: 'top' }
      ),
      new ConnectionPositionPair(
        { originX: 'start', originY: 'top' },
        { overlayX: 'start', overlayY: 'bottom' }
      ),
    ];

    return this.overlay
      .position()
      .flexibleConnectedTo(this.origin)
      .withPositions(positions)
      .withFlexibleDimensions(false)
      .withPush(false);
  }

  get origin() {
    return this.host.nativeElement;
  }
}

export function overlayClickOutside(
  overlayRef: OverlayRef,
  origin: HTMLElement
) {
  return fromEvent<MouseEvent>(document, 'click').pipe(
    filter((event) => {
      const clickTarget = event.target as HTMLElement;
      const notOrigin = clickTarget !== origin; // the input
      const notOverlay =
        !!overlayRef &&
        overlayRef.overlayElement.contains(clickTarget) === false; // the autocomplete
      return notOrigin && notOverlay;
    }),
    takeUntil(overlayRef.detachments())
  );
}
