import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorInterceptor, FeatAuthModule, JwtInterceptor } from '@calendar/feat-auth';
import { FeatHeaderModule } from '@calendar/feat-header';
import { FeatNavModule } from '@calendar/feat-nav';
import { FeatNotificationModule, NotificationService } from '@calendar/feat-notification';
import {
  API_URL_PREFIX,
  FeatSharedModule,
  LoadingSpinnerInterceptor,
  LoadingSpinnerService,
  NavigationDataService,
} from '@calendar/feat-shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './start/start.component';
import { effects, metaReducer, metaReducerProd, reducers } from '@calendar/feat-shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { appInitializerFactory } from './factories/app-initializer.factory';
import { Router } from '@angular/router';

@NgModule({
  declarations: [AppComponent, StartComponent],
  imports: [
    CommonModule,
    BrowserModule,
    // BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    FeatSharedModule,
    FeatHeaderModule,
    FeatNavModule,
    FeatAuthModule,
    FeatNotificationModule,
    NoopAnimationsModule,
    StoreModule.forRoot(reducers, { metaReducers: environment.production ? metaReducerProd : metaReducer }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      // logOnly: environment.production, // Restrict extension to log-only mode
      // autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    // StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    NotificationService,
    LoadingSpinnerService,
    NavigationDataService,
    { provide: API_URL_PREFIX, useValue: environment.apiUrlPrefix },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingSpinnerInterceptor, multi: true },
    /*
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Injector, Router],
      multi: true,
    },
    */
    // {provide: ErrorHandler, useClass: ErrorHandlerService}
    // { provide: APP_BASE_HREF, useValue: '/notes/', multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  /*
  constructor(
    private ngRedux: NgRedux,
    private devTools: DevToolsExtension) {

    let enhancers = [];
    // ... add whatever other enhancers you want.

    // You probably only want to expose this tool in devMode.
    if (__DEVMODE__ && devTools.isEnabled()) {
      enhancers = [ ...enhancers, devTools.enhancer() ];
    }

    this.ngRedux.configureStore(
      rootReducer,
      initialState,
      [],
      enhancers);
  }
*/
}
