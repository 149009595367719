import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  DefaultValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-x-autocomplete',
  templateUrl: './x-autocomplete.component.html',
  styleUrls: ['./x-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XAutocompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => XAutocompleteComponent),
      multi: true,
    },
  ],
})
export class XAutocompleteComponent extends DefaultValueAccessor implements OnInit {
  @Input()
  public model: Array<string> = [];

  @ViewChild('instance', { static: false }) public instance: NgbTypeahead = {} as NgbTypeahead;

  public click$ = new Subject<any>();

  public form = new FormGroup<any>({});
  public errors = false;

  public ngOnInit(): void {
    this.form = new FormGroup({
      autocompleteInput: new FormControl(),
    });

    this.form.valueChanges.subscribe((formValue) => {
      this.onChange({
        autocompleteInput: formValue.autocompleteInput,
      });
    });
  }

  public search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));

    return merge(debouncedText$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ''
          ? this.model
          : this.model.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
        ).slice(0, 10)
      )
    );
  };

  public override writeValue(value: any): void {
    this.form.setValue(value, { emitEvent: false });
  }

  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public validate(c: FormControl): ValidationErrors {
    let hasError = false;
    this.errors = false;

    const errorRet = {
      inputError: {
        valid: false,
        messages: [],
      } as InputError,
    };

    const input = c.value.autocompleteInput;
    if (input.length > 20) {
      hasError = true;
      this.errors = true;
      errorRet.inputError.messages.push('Input Error');
    }

    return hasError ? errorRet : {};
  }
}

export interface InputError {
  valid: boolean;
  messages: string[];
}
