import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from './app-config.interface';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private configData: AppConfig = {} as AppConfig;

  get config(): AppConfig {
    return this.configData;
  }

  constructor(private httpClient: HttpClient) {}

  public load(configFilePath: string): any {
    return new Promise<void>((resolve, reject) => {
      this.httpClient
        .get(configFilePath)
        .toPromise()
        .then((response: any) => {
          this.configData = response;
          console.log('App config : ', this.configData);
          resolve();
        })
        .catch((response: any) => {
          console.error('Cant load config file !!!', configFilePath);
          this.configData = {} as AppConfig;
          resolve();
        });
    });
  }
}
