<app-control-wrapper [orientation]="orientation" [label]="label ?? ''" [for]="id">
  <div class="d-flex">
    <app-control-error
      [showError]="(control?.invalid && control?.touched && control?.dirty) || false"
      [errors]="control?.errors"
    >
    </app-control-error>
    <div tabindex="-1" class="input-group" (keydown)="onKeyDown($event)" (focus)="onFocus()" appInputField>
      <p-dropdown
        appendTo="body"
        [baseZIndex]="2000"
        scrollHeight="250px"
        class="input-group"
        (onBlur)="onBlur($event)"
        (onShow)="onShow()"
        (onHide)="onHide()"
        [style]="{ width: '100%' }"
        [disabled]="disabled || readonly"
        placeholder="Select Item"
        [options]="internalData | objectToSelectItemTransformer: objectToSelectItemTransformerFn"
        [itemSize]="31"
        [filter]="false"
        [(ngModel)]="value"
      >
        <!--
        [options]="internalData | objectToSelectItemTransformer: objectToSelectItemTransformerFn"
-->
        <ng-template pTemplate="selectedItemTemplate" let-item>{{ item.label }}</ng-template>
      </p-dropdown>
    </div>
  </div>
</app-control-wrapper>
