import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_URL_PREFIX, User, UserExtended } from '@calendar/feat-shared';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserBehaviourSubject: BehaviorSubject<User>;
  private readonly endCurrentUserSubject: Subject<string>;

  constructor(
    @Inject(API_URL_PREFIX) private apiUrlPrefix: string,
    private readonly httpClient: HttpClient,
    private readonly router: Router
  ) {
    this.currentUserBehaviourSubject = new BehaviorSubject<User>({} as User);
    const user = localStorage.getItem('currentUser');
    if (user) {
      this.currentUserBehaviourSubject = new BehaviorSubject<User>(JSON.parse(user));
    }
    this.endCurrentUserSubject = new Subject();
    this.currentUserBehaviourSubject.pipe(takeUntil(this.endCurrentUserSubject));
  }

  public register(
    userName: string,
    userEmail: string,
    userPassword: string,
    userSecret: string
  ): Observable<User> {
    return this.httpClient
      .post<any>(this.apiUrlPrefix + 'auth/register', {
        name: userName,
        email: userEmail,
        password: userPassword,
        secret: userSecret,
      })
      .pipe(
        map((user) => {
          // console.log('User : ', user);
          return user;
        })
      );
  }

  public login(userNameOrEmail: string, userPassword: string): Observable<UserExtended> {
    return this.httpClient
      .post<any>(this.apiUrlPrefix + 'auth/login', { name: userNameOrEmail, password: userPassword })
      .pipe(
        map((user) => {
          // console.log('User :', user);
          // Login successful if there's a jwt token in the response
          if (user && user.access_token) {
            // Store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            console.log('expires at : ', moment().add(user.expires_in, 'second'));
            this.currentUserBehaviourSubject.next(user);
          }
          return user;
        })
      );
  }

  public logout(): void {
    console.log('logout');
    this.currentUserBehaviourSubject.next({} as User);
    this.endCurrentUserSubject.next('end');
    localStorage.removeItem('currentUser');
    this.router.navigate(['login']);
  }

  public get currentUserValue(): any {
    return this.currentUserBehaviourSubject.value;
  }

  public isUserLoggedIn(): boolean {
    return JSON.stringify(this.currentUserBehaviourSubject.value) !== JSON.stringify({});
  }
}
