import { Injectable } from '@angular/core';
import { QueryData, RouterStateData } from '../interfaces/context-menu.interface';

@Injectable({
  providedIn: 'root',
})
export class NavigationDataService {
  private prefix = 'notes-navigation-id-';

  constructor() {}

  public writeToLocalStorage(key: string | undefined, data: RouterStateData<QueryData> | undefined): void {
    localStorage.setItem(this.prefix + key, JSON.stringify(data));
  }

  public readFromLocalStorage(key: string): RouterStateData<QueryData> {
    const data = localStorage.getItem(this.prefix + key);
    let object: any;
    if (data) {
      object = JSON.parse(data) as RouterStateData<QueryData>;
    }
    localStorage.removeItem(this.prefix + key);
    return object;
  }
}
