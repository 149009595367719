import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { AbstractValueAccessor } from '../base/abstract-value-accessor';
import { MultiSelectDataItem } from './multi-select.interfaces';

@Component({
  selector: 'app-x-multi-select',
  templateUrl: './x-multi-select.component.html',
  styleUrls: ['./x-multi-select.component.scss'],
  providers: [],
})
export class XMultiSelectComponent
  extends AbstractValueAccessor<string[]>
  implements OnInit
{
  @Input()
  public data: Array<string> = [];

  public _data: MultiSelectDataItem[] = [];

  public selected: Array<boolean> = [];

  // @HostBinding('class') @Input() public override class: any;

  public maxLength = '10';

  @ViewChild('instance') public instance: NgbTypeahead = {} as NgbTypeahead;

  @ViewChild('instanceRef') public instanceRef: ElementRef = {} as ElementRef;

  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();

  public visible = false;
  public selectAll = false;

  public override ngOnInit(): void {
    super.ngOnInit();

    this.selected = new Array<boolean>(this.data.length).fill(false);

    for (const item of this.data) {
      this._data.push({ name: item, checked: false });
    }
    console.log('_data : ', this._data);
  }

  public toggleVisibility(): void {
    this.visible = !this.visible;
    this.focusInput();
  }

  public toggleSelectAll(): void {
    this.selectAll = !this.selectAll;
    for (const item of this._data) {
      item.checked = this.selectAll;
    }
    this.setValue(
      this._data.filter((item) => item.checked).map((item) => item.name)
    );
    this.focusInput();
  }

  public clearInput(): void {
    this.instance.writeValue('');
    this.focusInput();
  }

  public selectItem($event: NgbTypeaheadSelectItemEvent): void {
    console.log('Select item : ', $event);
    const data: MultiSelectDataItem = $event.item;
    for (const item of this._data) {
      if (item.name === data.name) {
        item.checked = true;
      }
    }
    this.setValue(
      this._data.filter((item) => item.checked).map((item) => item.name)
    );
  }

  public toggleSelectItem(name: string, $event: MouseEvent): void {
    $event.stopPropagation();
    console.log('Unselect : ', name);
    for (const item of this._data) {
      if (item.name === name) {
        item.checked = !item.checked;
      }
    }
    this.setValue(
      this._data.filter((item) => item.checked).map((item) => item.name)
    );

    // Check selectAll Checkbox
    let allSelected = true;
    for (const item of this._data) {
      if (!item.checked) {
        allSelected = false;
        break;
      }
    }
    this.selectAll = allSelected;
  }

  public itemsSelected(): string {
    return this._data.filter((item) => item.checked).length + ' items selected';
  }

  public search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clickWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clickWithClosedPopup$).pipe(
      map((term) =>
        term === ''
          ? this._data
          : this._data
              .filter(
                (item) =>
                  item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 5)
      )
    );
  };

  public formatter = (x: { name: string }) => x.name;

  /*
    TODO: Set input to ngbTypeahead and implement serach method

    public filter(event: KeyboardEvent): string[] {
      const filtered = this.data.filter(i => this.data.indexOf(i).includes(event.value));
      console.log('Filtered : ', filtered);
      return filtered;
    }
  */

  private focusInput(): void {
    setTimeout(() => {
      this.instanceRef.nativeElement.focus();
    }, 0);
  }

  /*
    public validate(control: AbstractControl): ValidationErrors | null {
      let hasError = false;

      let errorRet: { textLength: any[] };
      errorRet = {
        textLength: []
      };

      const input = control.value;

      if (input && input.length > this.maxLength) {
        hasError = true;
        errorRet.textLength.push('XXX');
      }

      return hasError ? errorRet : null;
    }
  */
}
