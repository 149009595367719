import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogType, ConfirmMessage } from './confirm-dialog.enum';
import {
  ConfirmDialogConfig,
  ConfirmDialogItemDetails,
  PredefinedMessages,
} from './confirm-dialog.interface';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmService } from '../../services/confirm/confirm.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  providers: [DialogService],
})
export class ConfirmDialogComponent<T> implements OnInit {
  @Input()
  public config: ConfirmDialogConfig = {} as ConfirmDialogConfig;

  @Input()
  public itemDetails = {} as ConfirmDialogItemDetails<T>;

  public modalForm = new FormGroup({});

  public defaultConfig: ConfirmDialogConfig;
  public messagePrepend = '';
  public message = '';
  public messageAppend = '';

  /*
    public icons: TemplateIcons = {
      modalClose: faTimes
    }
  */

  public readonly predefinedMessages: PredefinedMessages = {
    empty: '',
    default: 'Are you sure?',
  };

  get dialogType(): any {
    return ConfirmDialogType;
  }

  constructor(
    public confirmService: ConfirmService<any>,
    public ref: DynamicDialogRef,
    public cfg: DynamicDialogConfig,
    private formBuilder: FormBuilder
  ) {
    this.defaultConfig = {
      dialogType: ConfirmDialogType.confirm,
      headline: 'Confirm',
      messageId: ConfirmMessage.default,
      buttonConfirm: 'Yes',
      buttonRevoke: 'No',
    };
  }

  public ngOnInit(): void {
    // Create form
    this.modalForm = this.formBuilder.group({});

    this.config = this.defaultConfig;
    this.message = this.predefinedMessages[ConfirmMessage[this.config.messageId || 'empty']];
  }

  public onClickConfirm(): void {
    if (this.config.dialogType === ConfirmDialogType.confirm) {
      this.ref.close({ confirmed: true });
    }
  }

  public onClickRevoke(): void {
    this.ref.close({ confirmed: false });
  }

  public onClickReset(): void {
    this.ref.close({ confirmed: false });
  }
}
