import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { CustomTransform } from '../utils/CustomTransfom.interface';

@Directive({
  selector: '[appInputFormatter]',
})
export class InputFormatterDirective implements OnInit {
  // Use alias :
  // Means, if the public variable 'appInputFormatter' would have another name e.g. 'inputFormatter' it would still work
  // If the variable name is 'appInputFormatter' instead of 'inputFormatter' no alias is necessary
  @Input('appInputFormatter')
  public inputFormatter: CustomTransform | null = null;

  private el: HTMLInputElement;

  constructor(
    // @Self() @Optional() private ngControl: NgControl,
    private elementRef: ElementRef
  ) {
    this.el = this.elementRef?.nativeElement;
  }

  public ngOnInit(): void {
    if (this.inputFormatter && this.inputFormatter.transform) {
      this.el.value = this.inputFormatter.transform(this.el.value);
    }
  }

  @HostListener('focus', ['$event.target.value'])
  public onFocus(value: string): void {
    if (this.inputFormatter && this.inputFormatter.parse) {
      this.el.value = this.inputFormatter.parse(value);
    }
  }

  @HostListener('blur', ['$event.target.value'])
  public onBlur(value: string): void {
    if (this.inputFormatter && this.inputFormatter.transform) {
      this.el.value = this.inputFormatter.transform(this.el.value);
    }
  }
}
