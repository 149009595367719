export interface ActionItem<TQueryData extends QueryData> {
  item: string;
  navigationItem?: NavigationItem<TQueryData>;
  visibleForUserGroup?: string;
  doAction?(rowData?: Array<any>): void;
  addNavigationItem?(rowData?: Array<any>): NavigationItem<TQueryData>;
}

export interface NavigationItem<TQueryData extends QueryData> {
  url: string;
  navigationId: string;
  routerStateData?: RouterStateData<TQueryData>;
}

export interface RouterStateData<TQueryData extends QueryData> {
  queryData: TQueryData;
  autoSubmit?: boolean;
}

export interface MenuAction<TQueryData extends QueryData> {
  actionItems?: Array<ActionItem<TQueryData>>;
  doFilter?(rowData?: any): Array<ActionItem<TQueryData>>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface QueryData {}
