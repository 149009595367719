<!--
<hr>
{{ internalData | json }}<br>
-->
<mat-form-field appearance="fill">
  <mat-label>{{label}}</mat-label>
<!--
  [disabled]="disableSelect.value"
    <mat-option></mat-option>
    selectedValue
-->

  <mat-select [(ngModel)]="value" >
    <mat-option></mat-option>
    <mat-option
      *ngFor="let option of internalData"
      [value]="option">
      {{ option.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<br>
value : {{ value | json }}
<!--
<hr>
-->
