import { ActionReducer, ActionReducerMap, createSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { UserState } from '../models';
import * as fromUser from './user.reducer';
import { ConfigState } from '../models';

export interface AppState {
  // readonly config: ConfigState;
  readonly user: UserState;
}

export const reducers: ActionReducerMap<AppState> = {
  //  config: fromConfig.reduer;
  user: fromUser.reducer,
};

export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('previous state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();
    return result;
  };
}

export const metaReducer: MetaReducer<AppState>[] = [logger, localStorageSyncReducer];
export const metaReducerProd: MetaReducer<AppState>[] = [localStorageSyncReducer];

// export const selectConfigState = (state: AppState) => state.config;
// export const selectConfig = createSelector((selectUserState, from))

export const selectUserState = (state: AppState) => {
  // console.log('selectUserState');
  return state.user;
};
export const selectUser = createSelector(selectUserState, fromUser.selectUser);
export const selectUserLoginSuccess = createSelector(selectUserState, fromUser.selectUserLoginSuccess);
export const selectUserLoginFailed = createSelector(selectUserState, fromUser.selectUserLoginFailed);

const reducerKeys = ['user'];
export function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return localStorageSync({ keys: reducerKeys })(reducer);
}
