<ng-container *ngIf="showLoadingSpinner">
  <div
    class="container-fluid fixed-top w-100 h-100"
    style="background-color: white; z-index: 100; opacity: 40%"
  ></div>
  <div class="container-fluid fixed-top w-100 h-100" style="z-index: 110">
    <div class="row align-items-center h-100">
      <div class="col-1 mx-auto">
        <div class="spinner-border text-primary" style="z-index: 120; opacity: 100%"></div>
        <span>Loading...</span>
      </div>
    </div>
  </div>
</ng-container>
