import { Component, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractValueAccessor } from '../base/abstract-value-accessor';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    /*
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
*/
  ],
})
export class InputComponent extends AbstractValueAccessor<string> {
  public maxLength = 1024;

  constructor(@Self() @Optional() controlDirective: NgControl) {
    super(controlDirective);
  }

  /*
    public validate(control: AbstractControl): ValidationErrors | null {
      let hasError = false;

      let errorRet: { textLength: any[] };
      errorRet = {
        textLength: []
      };

      const input = control.value;

      if (input && input.length > this.maxLength) {
        hasError = true;
        errorRet.textLength.push('XXX');
      }

      return hasError ? errorRet : null;
    }
  */
}
