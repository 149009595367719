<app-control-wrapper [orientation]="orientation" [label]="label" [for]="id">
  <div class="d-flex">
    <app-control-error
      [showError]="(control?.invalid && control?.touched && control?.dirty) || false"
      [errors]="control?.errors"
    >
    </app-control-error>

    <!-- select all / unselect all button -->
    <!-- autocomplete input -->
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="form-control" style="border-bottom-right-radius: 0; border-top-right-radius: 0">
          <input
            [id]="selectAllId"
            type="checkbox"
            [checked]="selectAll ? 'checked' : ''"
            [disabled]="disabled"
            (click)="toggleSelectAll()"
            (blur)="onBlurSelectAll()"
          />
        </div>
      </div>

      <span
        class="form-control"
        [hidden]="visible"
        [ngClass]="disabled ? 'disabled' : ''"
        (click)="toggleVisibility()"
      >
        {{ itemsSelected() }}
      </span>

      <input
        [id]="id"
        #instance="ngbTypeahead"
        #instanceRef
        class="form-control typeahead-input"
        [class.is-invalid]="control?.invalid && control?.touched && control?.dirty"
        type="text"
        autocomplete="off"
        spellcheck="false"
        [hidden]="!visible"
        [readOnly]="readonly"
        [disabled]="disabled"
        [ngbTypeahead]="search"
        [resultTemplate]="resultTemplate"
        [inputFormatter]="formatter"
        (focus)="onFocus($event)"
        (click)="onClick($event)"
        (blur)="onBlur($event)"
        (keydown)="!readonly && !disabled ? onKeyDown($event) : false"
        [(ngModel)]="model"
      />

      <div
        class="clear"
        [hidden]="!visible"
        style="
          width: 20px;
          margin-left: -20px;
          padding-right: 5px;
          margin-top: 3px;
          z-index: 100;
          vertical-align: center;
        "
        (click)="clearInput()"
      >
        <button [id]="clearButtonId" type="button" class="close">
          <span>&times;</span>
        </button>
      </div>

      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary icon icon-select"
          style="xborder: 1px solid #ced4da"
          type="button"
          [ngClass]="this.instance.isPopupOpen() ? 'icon-open' : ''"
        >
          <fa-icon
            [icon]="fasChevronDown"
            size="2x"
            (click)="$event.stopPropagation(); toggleVisibility()"
          ></fa-icon>
        </button>
      </div>
    </div>
  </div>
</app-control-wrapper>

<ng-template #resultTemplate let-r="result" let-t="term" class="dropdown-menu">
  <div class="d-flex align-items-center dropdown-template" (click)="toggleSelectItem(r.name, $event)">
    <input type="checkbox" [checked]="r.checked ? 'checked' : ''" />
    <div style="margin-left: 5px">
      {{ r.name }}
    </div>
  </div>
</ng-template>
