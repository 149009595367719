<div class="row row-centered">
  <div class="w-100">
    <div style="font-size: 30px; font-weight: bold">
      {{ title }}
    </div>
    <div *ngIf="userState$ | async as userState">
      <div *ngIf="userState.user?.id">
        User( ID: {{ userState.user?.id }} | Name: {{ userState.user?.name }} | email:
        {{ userState.user?.email }} )
      </div>
    </div>
    <div *ngIf="user$ | async as user">{{ user | json }}</div>
  </div>
</div>
<div class="" style="position: absolute; top: 25px; right: 17px">
  <button
    type="button"
    class="btn btn-primary"
    (click)="help(); $event.preventDefault()"
  >
    Help
  </button>
  <button
    type="button"
    class="btn btn-primary m-1"
    (click)="register(); $event.preventDefault()"
  >
    Register
  </button>
  <button
    *ngIf="userLoggedIn()"
    type="submit"
    class="btn btn-primary mr-1"
    (click)="logout(); $event.preventDefault()"
  >
    Logout
  </button>
</div>
