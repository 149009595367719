import { Injectable } from '@angular/core';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import {
  ConfirmDialogConfig,
  ConfirmDialogItemDetails,
} from '../../components/confirm-dialog/confirm-dialog.interface';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService<T> {
  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public cfg: DynamicDialogConfig
  ) {}

  public open(
    config: ConfirmDialogConfig = {} as ConfirmDialogConfig,
    itemDetails: ConfirmDialogItemDetails<any> = {} as ConfirmDialogItemDetails<any>
  ): void {
    // header: 'Confirm',
    this.ref = this.dialogService.open(ConfirmDialogComponent, {
      closeOnEscape: true,
      width: '70%',
    });
  }
}
