<!--
<ng-template #toolTipContent>
  <p *ngFor="let item of errors?.textlength" [hidden]="errors?.maxlength">{{ item }}</p>

  <p *ngIf="errors?.maxlength">
    Text to long
  </p>

  <p *ngIf="errors?.range">
    Range Error
  </p>
</ng-template>

<div class="error mr-1" *ngIf="showError" [ngbTooltip]="toolTipContent"></div>
-->

<div
  *ngIf="showError"
  class="error mr-1"
  [pTooltip]="errorMessages"
  [escape]="false"
  tooltipStyleClass="errors"
  tooltipZIndex="10000"
>
<!--
  <fa-icon [icon]="icons.error" size="1x"></fa-icon>
-->
</div>
