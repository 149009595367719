import { TableItem } from '../table-control/table-control.interface';
import { ConfirmDialogType, ConfirmMessage } from './confirm-dialog.enum';

export interface ConfirmDialogConfig {
  dialogType?: ConfirmDialogType;
  headline?: string;
  messageId?: ConfirmMessage;
  buttonConfirm?: string;
  buttonRevoke?: string;
  buttonReset?: string;
}

export interface ConfirmDialogItemDetails<T> {
  rowData: any[];
  tableItems: Array<TableItem<T>>;
  visibleColumns?: string[];
}

export interface ConfirmDialogResult {
  confirmed: boolean;
}

export interface PredefinedMessages {
  [key: string]: string;
}
