<ng-container *ngIf="data !== undefined && data.length !== undefined">
  <div class="tableWrapper">
    <div class="tableContainer" (scroll)="tableScrolled()">
      <table class="table table-striped table-hover mb-0" #table>
        <thead class="">
          <tr>
            <th scope="col" *ngIf="allowSelection" class="selection-allowed"></th>
            <!-- checkbox -->
            <th
              scope="col"
              *ngFor="let item of attributes; let $index = index"
              ondragover="return false"
              [draggable]="draggable"
              (dragstart)="onDragStart($event, $index)"
              (dragend)="onDragEnd($event)"
              (dragenter)="onDragEnter($event)"
              (dragleave)="onDragLeave($event)"
              (drop)="onDrop($event, $index)"
            >
              <!--
                       [ngClass]="item.width ? item.width : ''"
             -->
              {{ item.header }}
            </th>
            <!--
                    <th scope="col"
                        ondragover="return false"
                        (dragenter)="onDragEnter($event)"
                        (dragleave)="onDragLeave($event)"
                        (drop)="onDrop($event)">
                    </th>
          --></tr>
        </thead>
        <!--
          <tfoot>
          <tr>
            <td colspan="100%">Pagination</td>
          </tr>
          </tfoot>
        -->
        <tbody>
          <!--
          !!!!!
                Use ng-container inside tables to implement *ngif's or *nfFor loops(div's are not possible)
                <ng-container *ngIf="...">
          !!!!!
          -->
          <!--
              <tr *ngFor="let row of data" [tabIndex]="i" (contextmenu)="doContextMenuAction($event, row)">
        -->
          <tr
            *ngFor="
              let row of data | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize;
              let rowIndex = index
            "
            [tabIndex]="0"
            (click)="toggleSelectRow(row)"
            [ngClass]="row.selected ? 'selected' : ''"
            (contextmenu)="doContextMenuAction($event, row); $event.preventDefault()"
          >
            <td *ngIf="allowSelection" class="selection-allowed">
              <input
                type="checkbox"
                [name]="'selected_' + (row.id || (rowIndex + (page - 1) * pageSize).toString())"
                [checked]="row.selected ? 'checked' : ''"
                (click)="toggleSelectRow(row); $event.stopPropagation()"
              />
            </td>
            <td *ngFor="let item of attributes; let itemIndex = index">
              <ng-container *ngIf="!item.isEditable">
                {{ getValue(row[item.attribute], item.path || '') | formatCell: item.format || '' }}
              </ng-container>
              <ng-container *ngIf="item.isEditable">
                <input [value]="row[item.attribute]" (keyup)="updateData(rowIndex, item.attribute, $event)" />
              </ng-container>
            </td>
          </tr>
          <!--
                </ng-container>
        --></tbody>
      </table>
    </div>
    <div class="tableFooter">
      <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="data.length"></ngb-pagination>
      <div class="tableFooterInfo">Records : {{ data.length }}</div>
    </div>
  </div>
</ng-container>

<!-- rowDataRef is the attached data (see table component : this.overlayRef.attach) -->
<ng-template #contextMenu let-rowDataRef>
  <app-context-menu-control
    [menuAction]="menuAction"
    [rowData]="rowDataRef"
    (closeContextMenu)="closeContextMenu()"
  >
  </app-context-menu-control>
</ng-template>
