import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appInputField]',
})
export class InputFieldDirective {
  /**
   * for complex components where focus() will not affect input state
   * add "click" as parameter so that click() will be triggered
   * <input appInputField> will trigger focus()
   * <any-component appInputFiled="click"></any-component> will trigger click()
   */
  @Input() public appInputField: string;

  constructor(public el: ElementRef) {}
}
