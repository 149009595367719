import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@calendar/feat-auth';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  constructor(private router: Router, public authService: AuthService) {}

  public navigate(path: string): void {
    this.router.navigate([path]);
  }
}
