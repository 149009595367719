import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  @Input()
  public destinationUrl = 'login';

  public registerForm = new FormGroup<any>({});
  public error = '';

  constructor(
    private formBuilder: FormBuilder,
    // private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      username: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      secret: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
    });
  }

  public submit(): void {
    // Only submit if valid
    if (this.registerForm.invalid) {
      return;
    }

    this.authService
      .register(
        this.registerForm.controls['username'].value,
        this.registerForm.controls['email'].value,
        this.registerForm.controls['password'].value,
        this.registerForm.controls['secret'].value
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.error = '';
          this.router.navigate([this.destinationUrl]);
        },
        (error) => {
          this.error = error;
          console.log(error);
        }
      );
  }
}
