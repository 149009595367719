<form novalidate [formGroup]="loginForm" (keydown.enter)="$event.preventDefault()">
  <div class="row">
    <app-input label="Login" ngDefaultControl formControlName="username"> </app-input>
  </div>

  <div class="row">
    <app-input label="Password" type="password" ngDefaultControl formControlName="password"> </app-input>
  </div>

  <button type="submit" class="btn btn-primary" (click)="submit()">Login</button>

  <br />
</form>
