import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { v4 as UUID } from 'uuid';
import { ActionItem, MenuAction, QueryData } from '../../interfaces/context-menu.interface';
import { NavigationDataService } from '../../services/navigation-data.service';

// Add other filter conditions e.g. user privilege
export function filterMenuActions(
  menuAction: MenuAction<QueryData> | null,
  rowData: any
): Array<ActionItem<QueryData>> {
  console.log('filter action : ', menuAction, ' rowData : ', rowData);
  if (menuAction && rowData) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return menuAction && rowData ? menuAction.doFilter?.(rowData) : [];
  }
  return [];
}

@Component({
  selector: 'app-context-menu-control',
  templateUrl: './context-menu-control.component.html',
  styleUrls: ['./context-menu-control.component.scss'],
})
export class ContextMenuControlComponent implements OnInit {
  public visible = false;

  @Output()
  public closeContextMenu = new EventEmitter();

  private menuActionSubject = new BehaviorSubject<MenuAction<QueryData> | null>(null);
  private rowDataSubject = new BehaviorSubject<any[]>([]);
  public actionItem$: Observable<Array<ActionItem<QueryData>>> = of<Array<ActionItem<QueryData>>>();

  constructor(private navigationDataService: NavigationDataService) {}

  @Input()
  public set menuAction(action: MenuAction<QueryData>) {
    console.log('action : ', action);
    this.menuActionSubject.next(action);
  }

  @Input()
  public set rowData(data: any[]) {
    console.log('row data : ', data);
    this.rowDataSubject.next(data);
  }

  public ngOnInit(): void {
    this.actionItem$ = combineLatest([this.menuActionSubject, this.rowDataSubject]).pipe(
      tap(([menuAction, rowData]) => {
        console.log('*** Menu action : ', menuAction);
        console.log('*** Row data : ', rowData);
      }),
      map(([menuAction, rowData]) => {
        return filterMenuActions(menuAction, rowData).map((actionItem) => {
          actionItem.navigationItem = actionItem.addNavigationItem?.(rowData);
          if (actionItem.navigationItem) {
            actionItem.navigationItem.navigationId = UUID();
          }
          console.log('*** action item : ', actionItem);
          return actionItem;
        });
      }),
      tap((actionItems) => {
        this.visible = actionItems.length > 0;
        console.log('Visible : ', this.visible);
      })
    );
  }

  public onActionItemClick(actionItem: ActionItem<QueryData>): void {
    this.closeContextMenu.emit();
    actionItem.doAction?.(this.rowDataSubject.value);
  }

  public onNavigationActionItemClick(actionItem: ActionItem<QueryData>, $event: MouseEvent): void {
    this.closeContextMenu.emit();
    // Use service to store state to local storage - if new tab (cmd + click)
    // If ctrl or cmd key
    if ($event.ctrlKey || $event.metaKey) {
      this.navigationDataService.writeToLocalStorage(
        actionItem?.navigationItem?.navigationId,
        actionItem?.navigationItem?.routerStateData
      );
    }
  }
}
