import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MenuAction, QueryData } from '../../interfaces/context-menu.interface';
import { fromEvent, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { TableItem } from './table-control.interface';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-table-control',
  templateUrl: './table-control.component.html',
  styleUrls: ['./table-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TableControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TableControlComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'table',
})
export class TableControlComponent<T> {
  @Input()
  public attributes: Array<TableItem<T>> = [];

  @Input()
  public data: Array<any> = [];

  @Input()
  public menuAction: MenuAction<QueryData> = {} as MenuAction<QueryData>;

  @Input()
  public allowSelection = false;

  @Input()
  public checkDialog = false;

  @Input()
  public draggable = false;

  @Output()
  public rowSelectedEvent = new EventEmitter<any>();

  public contextMenuVisible = false;

  // public selectedRowData: any[];

  public page = 1;
  public pageSize = 10;

  /*
    @ViewChild('table')
    public element: ElementRef;
  */

  @ViewChild('contextMenu') public contextMenu: TemplateRef<any> = {} as TemplateRef<any>;

  private targetRow: any;

  private dragged = -1;

  private overlayRef: OverlayRef | null = null;

  private contextMenuSubscription: Subscription | null = null;

  private static moveColumn(array: Array<TableItem<any>>, from: number, to: number = array.length): void {
    from >= to
      ? array.splice(to, 0, array.splice(from, 1)[0])
      : array.splice(to - 1, 0, array.splice(from, 1)[0]);
  }

  constructor(public overlay: Overlay, public viewContainerRef: ViewContainerRef) {}

  get hasContextMenu(): boolean {
    return (
      (this.menuAction && this.menuAction.actionItems && this.menuAction.actionItems.length > 0) ?? false
    );
  }

  /*
    public doSelectRow(row: any): void {
      console.log('Row data in table : ', row);
      row.selected = true;
      this.rowSelectedEvent.emit(row);
    }
  */

  public toggleSelectRow(row: any): void {
    row.selected = !row.selected;
    if (row.selected) {
      this.rowSelectedEvent.emit(row);
    }
    console.log('Toggle select row : ', row);
    console.log(
      'Selected rows : ',
      this.data.filter((item) => item.selected)
    );
  }

  public doContextMenuAction({ x, y, target }: MouseEvent, rowData: any): void {
    console.log('context menu action', x, ' : ', y, ' : ', target);
    this.closeContextMenu();

    console.log('Table row : ', rowData);
    let selectedRowData = [rowData];

    // Convert to array to handle multi selection as well
    const selectedRows = this.data.filter((item) => item.selected)
      ? this.data.filter((item) => item.selected)
      : [];
    if (this.allowSelection && selectedRows.length > 0) {
      selectedRowData = selectedRows;
      // Open context menu only if mouse position is over selected columns
      if (!selectedRowData.includes(rowData)) {
        return;
      }
    }

    // Decide whether context menu for selected rows or single not selected row
    if (selectedRows.length === 0) {
      this.targetRow = target;
      const style = getComputedStyle(document.body);
      const color = style.getPropertyValue('--blue');
      if (this.targetRow && this.targetRow.parentElement) {
        this.targetRow.parentElement.setAttribute(
          'style',
          'background-color:' + color + ' !important;' + 'color:' + 'white' + ' !important;'
        );
      }
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' },
        { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'top' },
        { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'bottom' },
        { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'bottom' },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    console.log('Selected row data : ', selectedRowData);
    this.overlayRef.attach(
      new TemplatePortal(this.contextMenu, this.viewContainerRef, {
        $implicit: selectedRowData,
      })
    );

    this.contextMenuSubscription = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter((event) => {
          console.log('Subscription');
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe(() => {
        console.log('Click -> context menu close');
        this.closeContextMenu();
      });

    // this.windowScrollingService.disable();
  }

  public tableScrolled(): void {
    if (this.overlayRef) {
      this.closeContextMenu();
    }
  }

  public closeContextMenu(): void {
    console.log('Closing context menu');
    this.contextMenuSubscription?.unsubscribe();
    this.contextMenuSubscription = null;
    this.overlayRef?.dispose();
    this.overlayRef = null;

    if (this.targetRow?.parentElement) {
      this.targetRow.parentElement.setAttribute('style', '');
    }
  }

  public updateData(rowIndex: number, itemName: string, event: KeyboardEvent): void {
    console.log('row : ', rowIndex);
    console.log('itemName : ', itemName);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    console.log('event : ', event.target.value);
    console.log('data : ', this.data);
    const item = this.data[rowIndex];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    item[itemName] = event.target.value;
  }

  public onDragStart($event: DragEvent, $index: number): void {
    this.dragged = $index;
    /*
        if ($event.target) {
          $event.target.style.opacity = 0.3;
        }
    */
  }

  public onDragEnd($event: DragEvent): void {
    this.dragged = -1;
    // $event.target.style.opacity = null;
  }

  public onDragEnter($event: DragEvent): void {
    // style borderLeft ...
  }

  public onDragLeave($event: DragEvent): void {
    // borderLeft = null;
  }

  public onDrop($event: DragEvent, $index = -1): void {
    /*
        const moveColumn = (array: TableItem[], from: number, to: number = array.length) => {
          from >= to ?
            array.splice(to, 0, array.splice(from, 1)[0]) :
            array.splice(to - 1, 0, array.splice(from, 1, )[0]);
        };
    */
    this.dragged || this.dragged === 0
      ? TableControlComponent.moveColumn(
          this.attributes,
          this.dragged,
          $index || $index === 0 ? $index : undefined
        )
      : console.warn('dragEvent form other source');
    // $event.target.style.borderLeft = null;
    this.dragged = -1;
    $event.preventDefault();
  }

  // Get attributes of an object chain (e.g. Notes.Category.name)
  public getValue(obj: any, path: string): string {
    if (!obj) {
      return '';
    }
    if (!path || path === '') {
      return obj;
    }
    const properties: Array<string> = path.split('.');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.getValue(obj[properties.shift()], properties.join('.'));
  }
}
