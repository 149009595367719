import { Injectable } from '@angular/core';
// import { AuthService } from '@calendar/feat-auth';
// import { UserActionTypes } from '../actions/user.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import '@ngrx/store';
import { exhaustMap, tap } from 'rxjs/operators';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions) {} // , private authService: AuthService) {}

  //
  // !!!!!
  //
  // If AuthService is used -> move first to shared feature (else: Warning: Circular dependency detected)
  //
  //

  /*
  @Effect()
  login$ = this.actions$.pipe(
    ofType(UserActionTypes.LOGIN_SUCCESS),
    /!*
    exhaustMap(() => {
      return this.authService.xxx('a', 'b');
    })
    *!/
    tap(() => {
      console.log('##### Login success effect');
    })
  );
*/
}
