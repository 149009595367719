import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppState, loginSuccess } from '@calendar/feat-shared';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input()
  public destinationUrl = '';

  public loginForm = new FormGroup<any>({});
  public error = '';

  constructor(
    private formBuilder: FormBuilder,
    // private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private store: Store<AppState>
  ) {
    // console.log('State : ', router.getCurrentNavigation().extras.state);
  }

  public ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(8)]),
      ],
    });
  }

  public submit(): void {
    // Only submit if valid
    if (this.loginForm.invalid) {
      return;
    }

    this.authService
      .login(
        this.loginForm.controls['username'].value,
        this.loginForm.controls['password'].value
      )
      .pipe(first())
      .subscribe(
        (user) => {
          console.log('##### User in login : ', user);
          if (user.user_id) {
            this.store.dispatch(
              loginSuccess({
                payload: {
                  id: user.user_id.id,
                  name: user.user_id.name,
                  email: user.user_id.email,
                },
              })
            );
          }
          this.error = '';
          this.router.navigate([this.destinationUrl]);
        },
        (error) => {
          this.error = error;
          console.log(error);
        }
      );
  }
}
