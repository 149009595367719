import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@calendar/feat-auth';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-notes-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'Notes';
  public env = environment;

  constructor(
    // private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {}

  public onRegister(): void {
    console.log('Register');
    this.router.navigate(['register']);
  }

  public userLoggedIn(): boolean {
    return this.authService.isUserLoggedIn();
  }
}
