import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MenuItem } from './menu-item';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input()
  public x = 0;

  @Input()
  public y = 0;

  @Input()
  public menuItems: Array<MenuItem> = [];

  @Input()
  public visible = false;

  @Output()
  public closeMenu = new EventEmitter();

  @ViewChild('menu', { static: false }) public menu: ElementRef = {} as ElementRef;

  constructor() {} // private actionService: ActionService

  public focus(): void {
    this.menu.nativeElement.focus();
  }

  public blur(): void {
    this.closeMenu.emit();
  }

  public hide(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  public action(menuItem: MenuItem): void {
    // this.actionService.navigate(menuItem.action.routerLink, {});
  }
}
