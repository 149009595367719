import { Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api';

export type ObjectToSelectItemTransformerFn = (o: any) => SelectItem;

@Pipe({
  name: 'objectToSelectItemTransformer',
})
export class ObjectToSelectItemTransformerPipe implements PipeTransform {
  transform(object: any, objectToSelectItemTransformer: ObjectToSelectItemTransformerFn): SelectItem[] {
    if (object && objectToSelectItemTransformer && object.length > 0) {
      return object.map((item: any) => {
        return objectToSelectItemTransformer(item);
      });
    }
    return [];
  }
}
