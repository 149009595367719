export interface Config {
  appTitle: string;
  appStage: string;
  apiServer: string;
  // ...
}

export interface ConfigState {
  data: Config | null;
  loading: boolean;
  loaded: boolean;
  error: Error | null;
}
