<!--
     [hidden]="!visible"
-->
<div tabindex="0" class="context-menu" (contextmenu)="$event.preventDefault()">
  <ul class="list-unstyled context-menu_list">
    <li *ngFor="let actionItem of actionItem$ | async as actionItems">
      <div *ngIf="actionItem.navigationItem" class="context-menu_list_item">
        <a
          (click)="onNavigationActionItemClick(actionItem, $event)"
          [queryParams]="{ navigationId: actionItem.navigationItem.navigationId }"
          [routerLink]="actionItem.navigationItem.url"
          [state]="actionItem.navigationItem.routerStateData"
        >
          {{ actionItem.item }}
        </a>
      </div>
      <div
        *ngIf="!actionItem.navigationItem"
        class="context-menu_list_item"
        (click)="onActionItemClick(actionItem)"
      >
        {{ actionItem.item }}
      </div>
    </li>
  </ul>
</div>
