<form novalidate [formGroup]="form">
  <div class="row">
    <div class="col">
      <div class="form-row">
        <div class="input-group mb-2">
          <input
            formControlName="autocompleteInput"
            type="text"
            class="form-control typeahead"
            spellcheck="false"
            [class.is-invalid]="errors"
            (click)="click$.next($event)"
            [ngbTypeahead]="search"
            #instance="ngbTypeahead"
          />
          <!--
              <svg-icon src="icons/ic_attach_file_48px.svg" [svgStyle]="{ 'width.px':90 }"></svg-icon>
          -->
          <!--
              <svg-icon src="/src/app/assets/img/calendar-icon.svg" [svgStyle]="{ 'width.px':90 }"></svg-icon>
          -->
          <!--
              <svg class="svg-24px">
                <use xlink:href="src/assets/img/calendar-icon.svg"></use>
              </svg>
          -->
          <!--
                <svg class="svg-24px">
                  <rect x="0" y="0" width="100" height="100" fill="coral"/>
                  <use xlink:href="src/icons/ic_attach_file_48px.svg"></use>
                </svg>
          -->

          <img
            class="input-group-append"
            src="assets/img/calendar-icon.svg"
            class="form-control svg-38px b-w"
          />
        </div>
      </div>
    </div>
  </div>
</form>
