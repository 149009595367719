import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { ColumnSize } from '../../utils/column-size.enum';
import { Orientation } from '../../utils/orientation';
import { AbstractGroupValueAccessor } from '../base/abstract-group-value-accessor';
import { DateTimeRange } from './date-time-range.interface';

@Component({
  selector: 'app-date-time-range',
  templateUrl: './date-time-range.component.html',
  styleUrls: ['./date-time-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeRangeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateTimeRangeComponent),
      multi: true,
    },
  ],
})
export class DateTimeRangeComponent extends AbstractGroupValueAccessor<DateTimeRange> {
  @Input()
  public labelFrom = 'Form';

  @Input()
  public labelTo = 'To';

  @Input()
  public orientation = Orientation.vertical;

  @Input()
  public readonly = false;

  get columnSize(): typeof ColumnSize {
    return ColumnSize;
  }

  public onBlur(): void {
    this.onTouched();
  }

  public onValueChange(): void {
    this.onChange(this.form.value);
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    console.log('Form value : ', this.form.value);
    if (control.value && (control.value.from || control.value.to)) {
      console.log('From : ', control.value.from, ' To : ', control.value.to);
      // moment check
      const fromValue = control.value.from;
      const toValue = control.value.to;

      let hasError = false;
      const errorValue: any = {};

      if (fromValue > toValue) {
        hasError = true;
        errorValue.range = true;
        this.form.controls['to'].setErrors(errorValue);
      } else {
        this.form.controls['to'].updateValueAndValidity();
      }

      return hasError ? errorValue : null;
    }
    return null;
  }
}
