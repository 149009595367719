import { Action, createReducer, on } from '@ngrx/store';
import { loginFailed, loginSuccess, logout } from '../actions/user.actions';
import { UserState } from '../models/userState';
import * as storageHelper from '../util/storage-helper';

export const initialUserState: UserState = {
  user: storageHelper.getItem('user').user,
  isAuthenticated: false,
  errorMessage: null,
};

const userReducer = createReducer(
  initialUserState,
  on(loginSuccess, (state, { payload }) => {
    console.log('State : ', state);
    console.log('Payload : ', payload);
    return { ...state, user: payload, isAuthenticated: true, errorMessage: '' };
  }),
  on(loginFailed, (state) => ({ ...state, isAuthenticated: false, errorMessage: 'Login failed' })),
  on(logout, (state) => ({ ...state, user: null, isAuthenticated: false, errorMessage: '' }))
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}

export const selectUser = (state: UserState) => state.user; // state?.user ? new User(state.user) : null);
export const selectUserLoginSuccess = (state: UserState) => state.isAuthenticated;
export const selectUserLoginFailed = (state: UserState) => state.errorMessage;
