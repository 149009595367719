<header class="container-fluid flex notes-panel mb-1">
  <app-header [title]="env.title ? env.title : title"></app-header>
  <pre>[Version: {{env.version}} Build Time: {{env.buildTime}}]</pre>
<!--
  <meta http-equiv="Content-Security-Policy" content="default-src http://localhost:4010; child-src 'none'; object-src 'none'">
-->
</header>
<main>
  <div *ngIf="userLoggedIn()" class="container-fluid flex notes-panel mb-1">
    <app-navigation></app-navigation>
  </div>

  <app-notification></app-notification>
</main>

<app-loading-spinner></app-loading-spinner>

<router-outlet></router-outlet>
