import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  public helpMapping: Map<string, string> = new Map([
    /*
    ['/notes', 'assets/notes-help.html'],
    ['/categories', 'assets/categories-help.html']
*/
  ]);

  constructor(http: HttpClient) {
    http.get<Array<Array<string>>>('assets/mapping.json').subscribe((data: Array<Array<string>>) => {
      // Map<string, string>) => {
      console.log('Mapping data :', data);
      data.forEach((keyValue) => this.helpMapping.set(keyValue[0], keyValue[1]));
      // this.helpMapping = data;
      console.log('helpMapping : ', this.helpMapping);
    });
  }

  public getCurrentHelpTopic(topic: string): string {
    return this.helpMapping.get(topic) ?? '';
  }
}
