import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { faChevronDown as fasChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { IdGenerator } from '../../utils/IdGenerator';
import { AbstractValueAccessor } from '../base/abstract-value-accessor';
import { MultiSelectSingleRowDataItem } from './multi-select-single-row.interfaces';

@Component({
  selector: 'app-x-multi-select-single-row',
  templateUrl: './x-multi-select-single-row.component.html',
  styleUrls: ['./x-multi-select-single-row.component.scss'],
  providers: [],
})
export class XMultiSelectSingleRowComponent
  extends AbstractValueAccessor<string[]>
  implements OnInit, AfterViewChecked, OnChanges
{
  @Input()
  public data: Array<string> = [];

  public _data: MultiSelectSingleRowDataItem[] = [];

  public model: MultiSelectSingleRowDataItem =
    {} as MultiSelectSingleRowDataItem;

  @Input()
  public selectAll = false;

  // @HostBinding('class') @Input() public override class = '';

  public selectAllId = IdGenerator.randomId();
  public clearButtonId = IdGenerator.randomId();

  @ViewChild('instance') public instance: NgbTypeahead = {} as NgbTypeahead;

  @ViewChild('instanceRef') public instanceRef: ElementRef = {} as ElementRef;

  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();

  public visible = false;

  public fasChevronDown = fasChevronDown;

  public override ngOnInit(): void {
    super.ngOnInit();

    // this.selected = new Array<boolean>(this.data.length).fill(false);

    /*
        if (this.data && this.data.length > 0 ) {
          for (const item of this.data) {
            this._data.push({name: item, checked: this.selectAll});
          }
        }
        console.log('_data : ', this._data);
    */
  }

  public ngOnChanges(changes: SimpleChanges): void {
    console.log('Changes : ', changes['data'].currentValue);
    console.log('Select all : ', this.selectAll);
    this.data = changes['data'].currentValue;
    this._data = [];
    if (this.data && this.data.length > 0) {
      for (const item of this.data) {
        this._data.push({ name: item, checked: this.selectAll });
      }
    }

    // TODO : Not possible : changed after is was checked error
    // BUG : Value not set but using 'Select All'
    // this.setValue(this._data.filter(item => item.checked).map(item => item.name));
  }

  public ngAfterViewChecked(): void {
    console.log('TODO ...');
  }

  public itemsSelected(): string {
    return this._data.filter((item) => item.checked).length + ' items selected';
  }

  public toggleVisibility(): void {
    if (!this.disabled) {
      this.visible = !this.visible;
      this.focusInput();
    }
  }

  public clearInput(): void {
    this.instance.writeValue('');
    this.toggleVisibility();
  }

  public toggleSelectAll(): void {
    this.selectAll = !this.selectAll;
    for (const item of this._data) {
      item.checked = this.selectAll;
    }
    this.setValue(
      this._data.filter((item) => item.checked).map((item) => item.name)
    );
    this.focusInput();
  }

  public onBlurSelectAll(): void {
    this.onTouched();
  }

  public toggleSelectItem(name: string, $event: MouseEvent): void {
    $event.stopPropagation();
    console.log('Unselect : ', name);
    for (const item of this._data) {
      if (item.name === name) {
        item.checked = !item.checked;
      }
    }
    this.setValue(
      this._data.filter((item) => item.checked).map((item) => item.name)
    );

    // Check selectAll Checkbox
    let allSelected = true;
    for (const item of this._data) {
      if (!item.checked) {
        allSelected = false;
        break;
      }
    }
    this.selectAll = allSelected;
    this.onTouched();
  }

  /*
    public selectItem($event: NgbTypeaheadSelectItemEvent): void {
      console.log('Select item : ', $event);
      const data: MultiSelectSingleRowDataItem = $event.item;
      for (const item of this._data) {
        if (item.name === data.name) {
          item.checked = true;
        }
      }
      this.setValue(this._data.filter(item => item.checked).map(item => item.name));
    }
  */

  public search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clickWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clickWithClosedPopup$).pipe(
      map((term) =>
        term === ''
          ? this._data
          : this._data
              .filter(
                (item) =>
                  item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 5)
      )
    );
  };

  public formatter = (x: { name: string }) => x.name;

  public onFocus(event: FocusEvent): void {
    this.focus$.next((event.target as HTMLInputElement).value);
  }

  public onClick(event: MouseEvent): void {
    this.click$.next((event.target as HTMLInputElement).value);
  }

  public override onBlur($event: FocusEvent): void {
    // Hide popup
    if (this.instance.isPopupOpen()) {
      this.instance.dismissPopup();
    }

    console.log('Event : ', $event);
    console.log('Related Target : ', $event.relatedTarget);
    const element = $event.relatedTarget as HTMLElement;
    console.log('Element : ', element);
    if (element && element.id === this.clearButtonId) {
      console.log('Element ID : ', element.id);
      this.clearInput();
    } else if (element && element.id === this.selectAllId) {
      console.log('Element ID : ', element.id);
    } else {
      this.toggleVisibility();
    }
    this.onTouched();
  }

  // Not necessary due to the implementation in the abstract class
  /*
    public setValue(value: string[], propagateChange: boolean = true): void {
      super.setValue(value);
      // super calls onChange
    }
  */

  // TOOD : Nearly the same code like toggleSelectedItem()
  public onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      const data: MultiSelectSingleRowDataItem = this.model;
      for (const item of this._data) {
        if (item.name === data.name) {
          item.checked = !item.checked;
        }
      }
      this.setValue(
        this._data.filter((item) => item.checked).map((item) => item.name)
      );

      // Check selectAll Checkbox
      let allSelected = true;
      for (const item of this._data) {
        if (!item.checked) {
          allSelected = false;
          break;
        }
      }
      this.selectAll = allSelected;
      this.onTouched();

      this.instanceRef.nativeElement.value = null;
      this.click$.next((event.target as HTMLInputElement).value);
    } else if (event.key === 'Tab') {
      this.instance.writeValue('');
    }
  }

  public override writeValue(value: string[]): void {
    const _value = value || [];

    console.log('Write value : ', _value);
    // Set Value
    if (_value && _value.length > 0 && _value[0] !== '') {
      // Reset selectAll checkbox
      this.selectAll = false;
      if (_value[0] === 'Select All') {
        this.selectAll = true;
        this._data = [];
        if (this.data && this.data.length > 0) {
          for (const item of this.data) {
            this._data.push({ name: item, checked: true });
          }
        }
      } else {
        // Reset
        this._data.forEach((item) => (item.checked = false));
        // Count items
        let itemsCount = 0;
        setTimeout(() => {
          _value.forEach((item) => {
            for (const _item of this._data) {
              if (_item.name === item) {
                _item.checked = true;
                itemsCount++;
              }
            }
          });
        }, 0);
        this.selectAll =
          this.data.length > 0 && this.data.length === itemsCount;
      }
    } else if (
      _value.length === 0 ||
      (_value.length === 1 && _value[0] === '')
    ) {
      if (this.instanceRef) {
        //        this.instanceRef.nativeElement._value = 0;
      }
      this._data.forEach((item) => {
        item.checked = false;
      });
      this.selectAll = false;
    }
  }

  private focusInput(): void {
    setTimeout(() => {
      this.instanceRef.nativeElement.focus();
    }, 0);
  }

  /*
    public validate(control: AbstractControl): ValidationErrors | null {
      const hasError = false;

      let errorRet: { textLength: any[] };
      errorRet = {
        textLength: []
      };

      const input = control.value;
      console.log('Input : ', input);

      if (input && input.length > this.maxLength) {
        hasError = true;
        errorRet.textLength.push('XXX');
      }

      return hasError ? errorRet : null;
    }
  */
}
