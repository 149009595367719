import { Directive, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup } from '@angular/forms';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractGroupValueAccessor<T> implements ControlValueAccessor {
  @Output()
  public blurInput = new EventEmitter();

  @Output()
  public valueChange = new EventEmitter();

  // Default (range from to) implementation. Overwrite in extending class if necessary
  public form: FormGroup = new FormGroup({
    from: new FormControl(),
    to: new FormControl(),
  });

  public writeValue(value: T): void {
    if (value) {
      this.form.setValue(value, { emitEvent: false });
    } else {
      this.form.reset();
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  public onChange = (_: any) => {};

  public onTouched = () => {};

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
