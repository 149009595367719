import { inject, NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { AuthGuard, RegisterComponent } from '@calendar/feat-auth';
import { StartComponent } from './start/start.component';

const defaultRoute = 'login';

export const children: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('@calendar/feat-auth').then((m) => m.FeatAuthModule),
  },
];

export const routes: Routes = [
  {
    path: 'login',
    component: StartComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'calendar',
    canActivate: [
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(next, state),
    ],
    loadChildren: () =>
      import('@calendar/feat-calendar').then(
        (m) => m.FeatCalendarRoutingModule
      ),
  },
  {
    path: 'notes',
    canActivate: [
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(next, state),
    ],
    loadChildren: () =>
      import('@calendar/feat-notes').then((m) => m.FeatNotesRoutingModule),
  },
  {
    path: 'notes/search',
    canActivate: [
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(next, state),
    ],
    loadChildren: () =>
      import('@calendar/feat-notes-search').then(
        (m) => m.FeatNotesSearchRoutingModule
      ),
  },
  {
    path: 'categories',
    canActivate: [
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(next, state),
    ],
    loadChildren: () =>
      import('@calendar/feat-category').then(
        (m) => m.FeatCategoryRoutingModule
      ),
  },
  // help
  {
    path: 'assets/**',
    redirectTo: 'assets/**',
  },
  // otherwise redirect to home
  {
    path: '**',
    redirectTo: defaultRoute,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  /*
  constructor(router: Router) {
  }
*/
}
