<div class="form-group">
  <div [class]="orientation === o.vertical ? 'd-flex flex-column' : 'form-row'">
    <label
      [class]="orientation === o.vertical ? 'text-wrap' : 'col-form-label col text-wrap'"
      *ngIf="label"
      [htmlFor]="for"
      >{{ label }}</label
    >
    <div [class]="orientation === o.vertical ? 'col p-0' : 'col'">
      <ng-content></ng-content>
    </div>
  </div>
</div>
