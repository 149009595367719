import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { ErrorType } from './control-error.enum';
import { ControlErrors } from './control-error.interface';

export interface TemplateIcons {
  [key: string]: IconDefinition;
}

@Component({
  selector: 'app-control-error',
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss'],
})
export class ControlErrorComponent {
  public icons: TemplateIcons = {
    error: faExclamationCircle,
  };

  public errorMessages: string;

  @Input()
  public set errors(errors: ValidationErrors | null | undefined) {
    errors ? this.updateMessage(errors) : (this.errorMessages = '');
  }

  @Input()
  public showError = false;

  constructor() {}

  public updateMessage(errors: ControlErrors): void {
    let errorMessages: string[] = [];

    for (const [errorType, error] of Object.entries(errors)) {
      const message = this.getErrorMessage(errorType as ErrorType, error);

      if (message) {
        errorMessages = Array.isArray(message) ? [...errorMessages, ...message] : [...errorMessages, message];
        this.errorMessages = '<p>' + errorMessages.join('</p><p') + '</p>';
      }
    }
  }

  public getErrorMessage(errorType: ErrorType, error: any): string | string[] | null {
    switch (errorType) {
      case ErrorType.Required:
        return 'Required ...';
      case ErrorType.Range:
        return 'Range ...';
      default:
        return 'Error (missing mapping in error component)';
    }
  }
}
