import { Pipe, PipeTransform } from '@angular/core';
import { CustomTransform } from '../utils/CustomTransfom.interface';

@Pipe({
  name: 'currency',
})
export class CustomCurrencyPipe implements CustomTransform, PipeTransform {
  public PADDING = '000000';

  private readonly DECIMAL_SEPARATOR: string;
  private readonly THOUSANDS_SEPARATOR: string;

  constructor() {
    // TODO comes from configuration settings
    this.DECIMAL_SEPARATOR = ',';
    this.THOUSANDS_SEPARATOR = '.';
  }

  public transform(value: number | string, fractionSize = 2): string {
    let [integer, fraction = ''] = (value || '').toString().split(this.DECIMAL_SEPARATOR);

    fraction =
      fractionSize > 0 ? this.DECIMAL_SEPARATOR + (fraction + this.PADDING).substring(0, fractionSize) : '';

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

    return integer + fraction;
  }

  public parse(value: string, fractionSize = 2): string {
    let [integer, fraction = ''] = (value || '').split(this.DECIMAL_SEPARATOR);

    // integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');
    integer = integer.split(this.THOUSANDS_SEPARATOR).join('');

    fraction =
      parseInt(fraction, 10) > 0 && fractionSize > 0
        ? this.DECIMAL_SEPARATOR + (fraction + this.PADDING).substring(0, fractionSize)
        : '';

    return integer + fraction;
  }
}
