import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState, logout } from '@calendar/feat-shared';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService, private store: Store<AppState>) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // add authorization header with jwt token if available
    const currentUser = this.authService.currentUserValue;
    // TODO : add access token to user object
    // console.log('### current user : ', currentUser);
    if (currentUser && currentUser.access_token) {
      const currentUserToken = currentUser.access_token;
      // console.log('Adding token to header : ', currentUserToken);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUserToken}`,
        },
      });
    } else {
      this.store.dispatch(logout());
    }

    return next.handle(request);
  }
}

export const jwtInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: JwtInterceptor,
  multi: true,
};
