// Frontend user object
export interface User {
  id: number;
  name: string;
  email?: string;
  password?: string;
  secret?: string;
}

// Backend user object returned to frontend from authentication service
export interface UserExtended {
  access_token: string;
  expires_in: number;
  status: number;
  user_id: User;
}
