export interface Dropdownable {
  id?: number;
  label?: string;
  name: string;
}

export interface PDropdownable {
  label: string;
  value: Dropdownable;
}

export interface DropdownData<T extends Dropdownable> {
  name: string;
  url: string;
  dropdown: Array<T>;
  // type: T;
}

/**
 * Key value structure. key string, value string
 * const map: DropdownData = {}
 * map['foo'] = 'bar'
 */
export interface DropdownKeyValue {
  [key: string]: string;
}
