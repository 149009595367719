import { CustomTransform } from './CustomTransfom.interface';

export class AmountTransformer implements CustomTransform {
  public transform(value: any): any {
    console.log('### Transform ...');
    return 'transform';
  }

  public parse(value: any): any {
    return 'xxx-' + value + '-xxx';
  }
}
