import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractValueAccessor } from '../base/abstract-value-accessor';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  providers: [],
})
export class TextComponent extends AbstractValueAccessor<string> implements AfterViewInit {
  @Input()
  public autofocus = false;

  @Output()
  public keyUpEvent = new EventEmitter<KeyboardEvent>();

  @ViewChild('textarea') private textarea: ElementRef<HTMLInputElement> = {} as ElementRef<HTMLInputElement>;

  /* Required?
    constructor(controlDirective: NgControl) {
      super(controlDirective);
    }
  */

  public ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => this.textarea.nativeElement.focus(), 0);
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    this.keyUpEvent.emit(event);
  }

  /*
    Already part of the config of the component. But can be overwritten here.

    public validate(control: AbstractControl): ValidationErrors | null {
      let hasError = false;

      let errorRet: { textLength: any[] };
      errorRet = {
        textLength: []
      };

      const text = control.value;

      if (text && text.length > 10) {
        hasError = true;
        errorRet.textLength.push('Text Error');
      }

      console.log('Has errors : ', hasError);
      return hasError ? errorRet : null;
    }
  */
}
