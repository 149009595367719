<form novalidate [formGroup]="registerForm" (keydown.enter)="$event.preventDefault()">
  <div class="w-100">
    <h5 style="text-align: center; margin-top: 5px; width: 100%">Register</h5>
  </div>

  <div class="row">
    <app-input label="Username" ngDefaultControl formControlName="username"> </app-input>
  </div>

  <div class="row">
    <app-input label="Email" ngDefaultControl formControlName="email"> </app-input>
  </div>

  <div class="row">
    <app-input label="Password" ngDefaultControl type="password" formControlName="password"> </app-input>
  </div>

  <div class="row">
    <app-input label="Secret" ngDefaultControl type="password" formControlName="secret"> </app-input>
  </div>

  <button type="submit" class="btn btn-primary" (click)="submit()">Register</button>

  <br />
</form>
