<div
  tabindex="0"
  class="menu"
  [ngStyle]="{ 'left.px': x, 'top.px': y }"
  [hidden]="!visible"
  (mouseenter)="focus()"
  (contextmenu)="hide($event)"
  (blur)="blur()"
  #menu
>
  <nav id="main-nav">
    <ul *ngFor="let menuItem of menuItems">
      <li (click)="action(menuItem)">
        <a>{{ menuItem.item }}</a>

        <ul *ngIf="menuItem && menuItem.subItem && menuItem.subItem.length > 0">
          <li *ngFor="let menuItem2 of menuItem.subItem" (click)="action(menuItem2)">
            <a>{{ menuItem2.item }}</a>

            <ul *ngIf="menuItem2 && menuItem2.subItem && menuItem2.subItem.length > 0">
              <li *ngFor="let menuItem3 of menuItem2.subItem" (click)="action(menuItem3)">
                <a>{{ menuItem3.item }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>
