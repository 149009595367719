import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfigService } from '../../services/app-config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class ApiConfigInterceptor {
  constructor(private appConfigService: AppConfigService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withCredentials: true,
      url:
        request.url.indexOf('assets/') === -1
          ? this.appConfigService.config.apiServer + request.url
          : request.url,
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event --->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        // TODO : Integrate server error interceptor here
        return throwError(error);
      })
    );
  }
}
